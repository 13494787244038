import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import styles from '../../assets/Table';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

class ModalAction extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      classes,
      modalNuevo,
      handleModal,
      handleSubmit,
      title,
      form,
    } = this.props;
    return (
      <Dialog
        open={modalNuevo}
        onClose={() => handleModal(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} style={{ backgroundColor: 'black' }}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => handleModal(false)}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {title}
            </Typography>
            <IconButton
              color="inherit"
              onClick={() => handleSubmit()}
              aria-label="Save"
            >
              <SaveIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>{form}</DialogContent>
      </Dialog>
    );
  }
}
export default withStyles(styles)(ModalAction);
