import React, { Component } from 'react';
import { Paper, Grid, Typography, TextField, IconButton, TableHead, TableRow, TableContainer, Table, TableCell, TableBody, Button, SwipeableDrawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../assets/Table';
import Axios from 'axios';
import { openSnackbar } from '../../components/msg/msg';
import { Assignment, Search } from '@material-ui/icons';

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numpla: "",
            nombre: "",
            sesion: "",
            datos: "",
            nombres: [],
            view: false,
            page: 0,
            rowsPerPage: 5,

        }
    }

    Consulta = () => {
        Axios.get("consultas/SesionesPlaca/" + this.state.numpla)
            .then(
                (result) => {
                    if (result.status === 200) {
                        this.setState({ datos: [] })
                        const { data, flag, msg } = result.data;
                        if (flag === false) {
                            openSnackbar({ message: msg, type: 'error' });
                        } else {
                            if (data.sesiones.length === 0) {
                                openSnackbar({ message: "No tiene  registros", type: 'error' });
                            }
                            for (let index = data.sesiones.length; index >= 0; index--) {
                                if (data.sesiones[index] !== undefined) {
                                    this.setState(
                                        {
                                            datos: [
                                                ...this.state.datos,
                                                data.sesiones[index],
                                            ]
                                        }
                                    );
                                }
                            }
                        }
                    } else {
                        openSnackbar({ message: result.statusText, type: 'error' });
                    }
                }
            )
            .catch((error) => {
                openSnackbar({ message: "Error al consumir xx", type: 'error' });
            });
    }

    TableContruct = (info) => {
        if (info.data[0] !== undefined && info.data[0] !== "") {
            return (
                <TableContainer className={this.props.classes.tablah}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {Object.entries(info.columns).map((data, index) => {
                                    return <TableCell key={index}> {data[1]} </TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {info.data.map((data) => {
                                if (data[0] === undefined && data.length === undefined) {
                                    return (
                                        <TableRow key={"Prow_1"}>
                                            {Object.entries(info.columns).map((subdata, subindex) => (
                                                <TableCell key={"Pcell_" + subindex}> {data[subdata[0]]} </TableCell>
                                            ))}
                                        </TableRow>
                                    )
                                } else if (data[0] !== undefined && data.length > 0) {
                                    return (
                                        data.map((subinfo, index) => (
                                            <TableRow key={"Srow_" + index}>
                                                {Object.entries(info.columns).map((subdata, subindex) => {
                                                    if (subdata[0] === "nombre") {
                                                        return (
                                                            <TableCell key={"Srow_" + subindex}>

                                                                {subinfo.Conductor.prinom +
                                                                    " " + subinfo.Conductor.segnom + " " + subinfo.Conductor.priape + " " + subinfo.Conductor.segape}
                                                            </TableCell>
                                                        )
                                                    }
                                                    if (subdata[0] === "detalle") {
                                                        return (
                                                            <TableCell key={"Scell_" + subindex}>
                                                                <Button onClick={() => { this.setState({ view: true, vehiculo: subinfo.sesion }) }}>
                                                                    <Assignment />
                                                                </Button>
                                                            </TableCell>
                                                        )
                                                    } else {
                                                        return <TableCell key={"Scell_" + subindex}> {subinfo[subdata[0]]} </TableCell>
                                                    }
                                                })}
                                            </TableRow>
                                        ))
                                    )
                                } else {
                                    return (
                                        <TableRow key={"Prow_1000000"}>
                                            <TableCell key={"Pcell_1000000"}> {"No hay registros para mostrar"} </TableCell>
                                        </TableRow>
                                    )
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
    }

    ModalInfo = () => {
        if (this.state.vehiculo) {
            let detalle = [];
            this.state.datos.map(data => {
                data.Detalle.map(datos => {
                    if (datos.sesion === this.state.vehiculo) {
                        detalle = data.Detalle;
                    }
                    return true
                })
                return true
            })
            return (
                <SwipeableDrawer
                    anchor="bottom"
                    open={this.state.view}
                    onClose={() => { this.setState({ view: false, vehiculo: "" }) }}
                    onOpen={() => { this.setState({ view: true, vehiculo: "" }) }}
                >
                    {this.TableContruct({
                        columns: {
                            dirini: "Dirección Inicial",
                            dirfin: "Dirección Final",
                            tiempo: "Tiempo",
                            distancia: "Distancia",
                        },
                        data: [detalle]
                    })}
                </SwipeableDrawer>
            )
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <Paper className={classes.root} style={{ padding: "3%" }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom component="h6">
                                {"Consultas por placa"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField value={this.state.numdoc} onChange={(elem) => { this.setState({ numpla: elem.target.value }) }} label="Número de placa" fullWidth />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton color="primary" aria-label="upload picture" component="span" onClick={this.Consulta}>
                                <Search />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "2%" }}>
                            {this.TableContruct({
                                columns: {
                                    nombre: "Nombre",
                                    fecini: "Fecha Inicio",
                                    fecfin: "Fecha Final",
                                    cantidad: "Total Carreras",
                                    valor: "Valor total",
                                    detalle: "Opciones",
                                },
                                data: [this.state.datos]
                            })}
                        </Grid>
                    </Grid>
                </Paper>
                {this.ModalInfo()}
            </div>
        );
    }
}

export default withStyles(styles)(Index);
