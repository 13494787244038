//components
import Mapa from "./pages/Map";
import Principal from "./pages/principal";
//basicas
//import Mia01 from './pages/mia01'
import Mia08 from "./pages/mia08";
import Mia06 from "./pages/mia06";
//import Mia14 from './pages/mia14'
import Mia16 from "./pages/mia16";
import Mia29 from "./pages/mia29";
//configuracion
import Mia09 from "./pages/mia09";
import Mia10 from "./pages/mia10";
import Mia21 from "./pages/mia21";
import Mia20 from "./pages/mia20";
import Mia19 from "./pages/mia19";
import Mia12 from "./pages/mia12";
import Mia22 from "./pages/mia22";
//Terceros
import Mia27 from "./pages/mia27";
import Mia30 from "./pages/mia30";
//Vehiculos
import Mia17 from "./pages/mia17";
//Facturacion
//import Mia23 from './pages/mia23'
// import Mia25 from '../pages/mia25'
import Gener02 from "./pages/gener02";
import Gener21 from "./pages/gener21";
import Gener41 from "./pages/gener41";
import Gener42 from "./pages/gener42";
import Consultas from "./pages/consultas";
import Placas from "./pages/consultaplaca";
import ConsultaConductor from "./pages/consultaConductor";
import ConsultaVehiculos from "./pages/consultaVehiculos";
import mia43 from "./pages/mia43";
//Assets
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Dashboard from "@material-ui/icons/Dashboard";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import People from "@material-ui/icons/People";
import Build from "@material-ui/icons/Build";
import Dns from "@material-ui/icons/Dns";
//import CreditCard from '@material-ui/icons/CreditCard';
import Maps from "@material-ui/icons/Map";
import { Assignment } from "@material-ui/icons";

export const operador = [
  { title: "Mapa", component: Mapa, icon: Maps, url: "map", dashboard: "true" },
  {
    title: "Terceros",
    dropdown: "dropdow3",
    icon: People,
    nodes: [
      {
        title: "Propietarios",
        component: Mia27,
        url: "mia27",
        dashboard: "true",
      },
      {
        title: "Conductores",
        component: Mia30,
        url: "mia30",
        dashboard: "true",
      },
    ],
  },
  {
    title: "Vehículos",
    component: Mia17,
    icon: DirectionsCar,
    url: "mia17",
    dashboard: "true",
  },
  {
    title: "Tablero",
    component: Principal,
    icon: Dashboard,
    url: "principal",
    dashboard: "true",
  },
];

export const propietario = [
  { title: "Mapa", component: Mapa, icon: Maps, url: "map", dashboard: "true" },
  {
    title: "Terceros",
    dropdown: "dropdow3",
    icon: People,
    nodes: [
      {
        title: "Conductores",
        component: Mia30,
        url: "mia30",
        dashboard: "true",
      },
    ],
  },
  {
    title: "Vehículos",
    component: Mia17,
    icon: DirectionsCar,
    url: "mia17",
    dashboard: "true",
  },
];

export const administrador = [
  {
    title: "Mapa",
    component: Mapa,
    icon: Maps,
    url: "map",
    dashboard: "true",
  },
  {
    title: "Administración",
    dropdown: "dropdow1",
    icon: Dns,
    nodes: [
      {
        title: "Datos de la Empresa",
        component: Mia08,
        url: "mia08",
        dashboard: "true",
      },
      {
        title: "Tipo de funcionarios",
        component: Gener21,
        url: "gener21",
        dashboard: "true",
      },
      {
        title: "Permisos por funcionario",
        component: Gener41,
        url: "gener41",
        dashboard: "true",
      },
      {
        title: "Usuarios",
        component: Gener02,
        url: "gener02",
        dashboard: "true",
      },
      {
        title: "Permisos por usuario",
        component: Gener42,
        url: "gener42",
        dashboard: "true",
      },
    ],
  },
  {
    title: "Configuración",
    dropdown: "dropdow2",
    icon: Build,
    nodes: [
      {
        title: "Tipos de vehículo",
        component: Mia09,
        icon: InboxIcon,
        url: "mia09",
        dashboard: "true",
      },
      {
        title: "Documentos obligatorios",
        component: Mia16,
        url: "mia16",
        dashboard: "true",
      },
      {
        title: "Formas de pago",
        component: Mia06,
        url: "mia06",
        dashboard: "true",
      },
      {
        title: "Recargo de Tarifas",
        component: Mia10,
        url: "mia10",
        dashboard: "true",
      },
      {
        title: "Marcas",
        component: Mia21,
        url: "mia21",
        dashboard: "true",
      },
      {
        title: "Líneas",
        component: Mia22,
        url: "mia22",
        dashboard: "true",
      },
      {
        title: "Aseguradoras",
        component: Mia20,
        url: "mia20",
        dashboard: "true",
      },
      {
        title: "Eps",
        component: Mia19,
        url: "mia19",
        dashboard: "true",
      },
      {
        title: "Tarifas básicas",
        component: Mia12,
        url: "mia12",
        dashboard: "true",
      },
      {
        title: "Tipos de Licencia",
        component: Mia29,
        url: "mia29",
        dashboard: "true",
      },
      {
        title: "Tipos de Carroceria",
        component: mia43,
        url: "mia43",
        dashboard: "true",
      },
    ],
  },
  {
    title: "Terceros",
    dropdown: "dropdow3",
    icon: People,
    nodes: [
      {
        title: "Propietarios",
        component: Mia27,
        url: "mia27",
        dashboard: "true",
      },
      {
        title: "Conductores",
        component: Mia30,
        url: "mia30",
        dashboard: "true",
      },
    ],
  },
  {
    title: "Consultas",
    dropdown: "dropdow4",
    icon: Assignment,
    nodes: [
      {
        title: "Consulta por propietario",
        component: Consultas,
        icon: Assignment,
        url: "consultas",
        dashboard: "true",
      },
      {
        title: "Consulta por placa",
        component: Placas,
        icon: Assignment,
        url: "consultaplaca",
        dashboard: "true",
      },
      {
        title: "Consulta Conductores",
        component: ConsultaConductor,
        icon: Assignment,
        url: "consultaConductor",
        dashboard: "true",
      },
      {
        title: "Consulta Vehiculos",
        component: ConsultaVehiculos,
        icon: Assignment,
        url: "consultaVehiculos",
        dashboard: "true",
      },
    ],
  },
  {
    title: "Vehículos",
    component: Mia17,
    icon: DirectionsCar,
    url: "mia17",
    dashboard: "true",
  },
  {
    title: "Tablero",
    component: Principal,
    icon: Dashboard,
    url: "principal",
    dashboard: "true",
  },
];

const routes = (props) => {
  const { cookies } = props;
  switch (cookies.cookies.tipfun) {
    case "ADAD":
      return administrador;
    case "OPER":
      return operador;
    case "PROP":
      return propietario;
    default:
      return propietario;
  }
};

export default routes;
