import React,{Component} from 'react'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

class Search extends Component {
    render() {
      const {
        value,
        onChange,
        onSubmit,
      } = this.props;
      return (
        <form onSubmit={onSubmit}>
          <TextField
            type="search"
            value={value}
            onChange={onChange}
            />
          <IconButton aria-label="Filter list" onClick={onSubmit}>
            <SearchIcon />
          </IconButton>
        </form>
      );
    }
  }
  export default Search