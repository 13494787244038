import React, { Component } from 'react';
import './assets/App.css';
import Dashboard from './components/layouts/dashboard';
import Login from './pages/login/login';
import Nomatch from './pages/nomatch/nomatch';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import { Route, Switch, Redirect } from 'react-router-dom';
import history from './history';
import routes from './routes'

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    history.push('/login');
  }
  return error.response;
});

const PrivateRoute = ({ component: Component, ...rest }) => {
  if (rest.dashboard !== "true") {
    return (
      <Route {...rest} cookies={rest.cookies} render={
        (props) => (
          fakeAuth.isAuthenticated === true
            ? <Component {...props} />
            : <Redirect to='/login' />
        )}
      />)
  } else {
    return (<Route {...rest} cookies={rest.cookies} render={(props) => (
      fakeAuth.isAuthenticated === true
        ? <Dashboard cookies={rest.cookies}><Component cookies={rest.cookies} {...props} /></Dashboard>
        : <Redirect to='/login' />
    )}
    />)
  }

};

const fakeAuth = {
  isAuthenticated: false,
  authenticate() {
    this.isAuthenticated = true;
  },
  signout() {
    this.isAuthenticated = false;
  }
}

class App extends Component {
  render() {
    const { cookies } = this.props;
    const mapeo = routes(this.props);
    if (cookies.get("token_auth") === undefined) {
      fakeAuth.signout();
    } else {
      fakeAuth.authenticate();
      axios.defaults.headers.common['Authorization'] = cookies.get("token_auth");
    }
    return (
      <Switch>
        <Route exact path="/login" render={() => (<Login cookies={this.props.cookies} />)} />
        <Route exact path="/" render={() => (<Login cookies={this.props.cookies} />)} />
        {mapeo.map((route, index) => {
          if (route.dropdown === undefined) {
            return <PrivateRoute key={index + "route"} exact cookies={this.props.cookies} dashboard={route.dashboard} path={"/" + route.url} component={route.component} />
          } else {
            return (
              route.nodes.map((subroute, sindex) => {
                return <PrivateRoute key={sindex + "route"} exact cookies={this.props.cookies} dashboard={subroute.dashboard} path={"/" + subroute.url} component={subroute.component} />
              })
            )
          }
        })}
        <PrivateRoute cookies={this.props.cookies} component={Nomatch} dashboard={"true"} />
        {/* <Route component={Nomatch} /> */}
      </Switch>
    );
  }
}
export default withCookies(App);
