import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import Msg from './components/msg/msg';
import history from './history';
import { Router } from 'react-router-dom';
import axios from 'axios';

import { url_base } from './config';

axios.defaults.baseURL = url_base;
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';
axios.defaults.headers.get['Content-Type'] =
  'application/x-www-form-urlencoded';

ReactDOM.render(
  <CookiesProvider>
    <Provider store={configureStore()}>
      <Router history={history}>
        <App />
        <Msg />
      </Router>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
);
document.getElementById('root').style.width = '100%';
document.getElementById('root').style.height = '100%';
document.getElementById('root').style.position = 'absolute';

//offline  true = register false = unregister
serviceWorker.unregister();
