import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import ReactSelectMaterialUi from 'react-select-material-ui';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

export const Form = (props) => {
  let {
    errors,
    touched,
    handleChange,
    setFieldTouched,
    handleSubmit,
    submitForm,
    bindSubmitForm,
    isEdit,
    handleDateChange,
  } = props;
  let option = [];
  bindSubmitForm(submitForm);

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const changeDate = (name, e) => {
    handleDateChange(name, e, props.values);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Grid container style={{ flexGrow: 1 }}>
          {Object.entries(props.values).map((val, i) => {
            if (isEdit === true) {
              isEdit = i === 0 ? true : false;
            }
            let opt =
              props.select === undefined ? undefined : props.select[val[0]];
            let fec =
              props.fecha === undefined ? undefined : props.fecha[val[0]];
            let archivo =
              props.archivo === undefined ? undefined : props.archivo[val[0]];

            if (opt !== undefined) {
              option = [];
              option.push({ value: '@', label: 'seleccione' });
              opt.length > 0 &&
                opt.map((item) => {
                  item = Object.entries(item);
                  return option.push({ value: item[0][1], label: item[1][1] });
                });
              return (
                <Grid key={i} item xs={6} sm={4} style={{ padding: 15 }}>
                  <ReactSelectMaterialUi
                    id={val[0]}
                    name={val[0]}
                    select
                    label={
                      props['colums'] !== undefined
                        ? props['colums'][i]
                        : val[0]
                    }
                    value={props.values[val[0]]}
                    onChange={(value) => changeDate(val[0], value)}
                    helperText={touched[val[0]] ? errors[val[0]] : ''}
                    error={touched[val[0]] && Boolean(errors[val[0]])}
                    style={{ width: '100%' }}
                    options={option}
                  />
                </Grid>
              );
            } else if (fec !== undefined) {
              return (
                <Grid key={i} item xs={6} sm={4} style={{ padding: 15 }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      openTo="date"
                      format="dd/MM/yyyy"
                      label={val[0]}
                      views={['year', 'month', 'date']}
                      value={
                        props.values[val[0]] !== ''
                          ? props.values[val[0]]
                          : null
                      }
                      onChange={changeDate.bind(null, [val[0]])}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              );
            /*Validamos si es un campo de tipo archivo */
            } else if (archivo !== undefined) {
                return (
                    <Grid key={i} item xs={6} sm={4} style={{ padding: 15 }}>
                    <label style={{fontSize: "1.25em",
                            display: "inline-block"}}> {val[0]}
                    <input type="file"
                    id={val[0]}
                    name={val[0]}
                    label={
                        props['colums'] !== undefined
                        ? props['colums'][i]
                        : val[0]
                    }
                    disabled={isEdit} //aqui
                    //value={props.values[val[0]]}
                    onChange={change.bind(null, [val[0]])}
                    />
                    </label>
                    </Grid>
                );
            } else {
                return (
                    <Grid key={i} item xs={6} sm={4} style={{ padding: 15 }}>
                    <TextField
                    id={val[0]}
                    name={val[0]}
                    helperText={touched[val[0]] ? errors[val[0]] : ''}
                    error={touched[val[0]] && Boolean(errors[val[0]])}
                    label={
                        props['colums'] !== undefined
                        ? props['colums'][i]
                        : val[0]
                    }
                    disabled={isEdit} //aqui
                    value={props.values[val[0]]}
                    onChange={change.bind(null, [val[0]])}
                    />
                    </Grid>
                );
            }
          })}
      </Grid>
      </form>
      </React.Fragment>
  );
};
