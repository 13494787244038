import React, { Component } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { LoginForm } from "./loginform";
import { openSnackbar } from "../../components/msg/msg";
import axios from "axios";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Paper, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import logo from "../../assets/image/logo1.png";
import { styles } from "../../assets/css/login";

const validationSchema = Yup.object({
  email: Yup.string("Ingrese su email o usuario").required(
    "Email es requerido"
  ),
  password: Yup.string("Ingrese su contraseña").required(
    "La contraseña es requerida"
  ),
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        email: "",
        password: "",
      },
      redirect: false,
    };
  }

  componentDidMount = () => {};

  setLogeo(result) {
    const { flag, msg, data, tipfun } = result;
    const { cookies } = this.props;
    if (flag === false) {
      openSnackbar({ message: msg, type: "error" });
    } else if (flag === true) {
      let date = new Date();
      date.setTime(date.getTime() + 60 * 1000);
      cookies.set("token_auth", data, {
        path: "/",
        expires: date,
        maxAge: 6000,
      });
      cookies.set("tipfun", tipfun);
      axios.defaults.headers.get["Authorization"] = data;
      axios.defaults.headers.post["Authorization"] = data;
      this.setState({
        redirect: true,
      });
    }
  }

  logearse(values) {
    const { email, password } = values;
    axios
      .post("login/autenticar", {
        email,
        password,
      })
      .then((result) => {
        if (result.status === 200) {
          this.setLogeo(result.data);
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consultar", type: "error" });
      });
  }

  render() {
    const { classes } = this.props;
    const { values, redirect } = this.state;
    if (redirect) return <Redirect to="/map" />;
    return (
      <main className={classes.main2}>
        <div className={classes.main}>
          <img
            src={logo}
            alt="logo"
            style={{ width: "50%", marginTop: "30%", marginLeft: "20%" }}
          />
          <Paper
            className={classes.paper}
            style={{
              marginTop: "5%",
              marginBottom: "30%",
              backgroundColor: "rgba(255,255,255,0.8)",
            }}
          >
            <Typography component="h1" variant="h5">
              Ingreso
            </Typography>
            <Formik
              render={(props) => <LoginForm {...props} />}
              initialValues={values}
              classes={classes}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                this.logearse(values);
                setSubmitting(false);
              }}
            />
          </Paper>
        </div>
      </main>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
