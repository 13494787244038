import React, { Component, Fragment } from "react";
import { styles } from "../../assets/css/tablero";
import { withStyles, Typography } from "@material-ui/core";
import SimpleLineChart from "./SimpleLineChart";
import SimpleTable from "./SimpleTable";

class Tablero extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Typography
          variant="h4"
          style={{ textAlign: "center" }}
          gutterBottom
          component="h4"
        >
          Carreras por día
        </Typography>

        <Typography component="div" className={classes.chartContainer}>
          <SimpleLineChart />
        </Typography>
        <Typography component="div" className={classes.chartContainer}>
          <SimpleTable />
        </Typography>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Tablero);
