import React, { Component } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paginate from "../../components/paginate/paginate";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import List from "@material-ui/icons/List";
import axios from "axios";
import { openSnackbar } from "../msg/msg";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import Search from "./search";
import TooltipIcon from "./../buttons/tooltipIcon";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../assets/Table";
import ConfirmationDialogRaw from "./confirmationDialogRaw";
import Gavel from "@material-ui/icons/Gavel";
import Dropdown from "react-bootstrap/Dropdown";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import FileCopy from "@material-ui/icons/FileCopy";
import CircularProgress from "@material-ui/core/CircularProgress";

class Tableini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [],
      page: 0,
      rowsPerPage: 5,
      searchTerm: "",
      total: 0,
      openDelete: false,
      primaryValue: "",
      isLoading: false,
    };
    this.url = this.props.url;
    this.openFiltrar = this.openFiltrar.bind(this);
    this.closeFiltrar = this.closeFiltrar.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
  }

  componentDidMount() {
    this.props.onRef(this);
    const { page, rowsPerPage, searchTerm } = this.state;
    this.search(page, rowsPerPage, searchTerm);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  handleOpenDelete = (value) => {
    this.setState({ openDelete: true, primaryValue: value });
  };

  handleOpenDetail = (value) => {
    this.setState({ openDt: true, primaryValue: value });
  };

  handleCloseDelete = (flag, value) => {
    if (flag === true) {
      this.props.options.handleDelete(value);
    }
    this.setState({ openDelete: false });
  };

  openFiltrar() {
    this.setState({ openfiltrar: true });
  }

  closeFiltrar() {
    const { searchTerm } = this.state;
    this.setState({ openfiltrar: false, searchTerm: "" });
    if (searchTerm !== "") {
      const { rowsPerPage } = this.state;
      this.search(0, rowsPerPage, "");
    }
  }

  onSearchChange(event) {
    this.setState({ searchTerm: event.target.value });
  }

  onSearchSubmit(event) {
    const { page, rowsPerPage, searchTerm } = this.state;
    this.search(page, rowsPerPage, searchTerm);
    event.preventDefault();
  }

  updatestate(result) {
    const { data, flag, msg } = result;
    if (flag === false) {
      openSnackbar({ message: msg, type: "error" });
      return false;
    }
    this.setState({
      columns: data.columns,
      data: data.data.items,
      total: parseInt(data.data.count),
    });
  }

  search(page, rowsPerPage, searchTerm) {
    let method = "";
    this.props.method === undefined
      ? (method = "All")
      : (method = this.props.method);
    this.setState({ isLoading: true });
    if (page === undefined) {
      page = this.state.page;
      rowsPerPage = this.state.rowsPerPage;
      searchTerm = this.state.searchTerm;
    }
    axios
      .get(this.url + "/"+method+"/" + page + "/" + rowsPerPage + "/" + searchTerm)
      .then((result) => {
        this.setState({ isLoading: false });
        if (result.status === 200) {
          this.updatestate(result.data);
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
    const { rowsPerPage, searchTerm } = this.state;
    this.search(page, rowsPerPage, searchTerm);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
    const { searchTerm } = this.state;
    this.search(0, event.target.value, searchTerm);
  };

  render() {
    const { classes, options } = this.props;
    const {
      data,
      columns,
      page,
      rowsPerPage,
      total,
      openfiltrar,
      searchTerm,
      isLoading,
    } = this.state;
    this.props.options.columns = columns;
    if (isLoading === true) {
      return <CircularProgress disableShrink />;
    }

    //!Validamos si la captura tiene Acciones
    let actions = "";
    if(this.props.options !== undefined){
        if(this.props.options.action == false){
            actions = null;
        }else{
            actions = <Th key='actions_th'>Acciones </Th>;
        }
    }

    return (
      <div>
        <Toolbar>
          <div>
            {openfiltrar ? (
              <Search
                value={searchTerm}
                onChange={this.onSearchChange}
                onSubmit={this.onSearchSubmit}
              />
            ) : (
              <Typography variant="h6" id="tableTitle">
                {this.props.title}
              </Typography>
            )}
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            {options.filter ? (
              openfiltrar ? (
                <TooltipIcon
                  value={searchTerm}
                  onChange={this.onSearchChange}
                  onSubmit={this.onSearchSubmit}
                  title="Close Filter"
                  action={() => this.closeFiltrar()}
                  icon={CloseIcon}
                  size="default"
                  color="secondary"
                />
              ) : (
                <TooltipIcon
                  title="Open Filter"
                  action={() => this.openFiltrar()}
                  icon={SearchIcon}
                  size="default"
                  color="primary"
                />
              )
            ) : (
              ""
            )}
            {options.report ? (
              <React.Fragment>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Something else
                  </Dropdown.Item>
                </Dropdown.Menu>
                <TooltipIcon
                  title="Reporte Pdf"
                  action={() => options.handleReport("pdf")}
                  icon={PictureAsPdf}
                  size="default"
                  color="secondary"
                />
                <TooltipIcon
                  title="Reporte Excel"
                  action={() => options.handleReport("excel")}
                  icon={FileCopy}
                  size="default"
                  color="secondary"
                />
              </React.Fragment>
            ) : (
              ""
            )}
            {options.nuevo ? (
              <TooltipIcon
                title="Nuevo"
                action={() => options.handleNuevo()}
                icon={CreateNewFolderIcon}
                size="default"
                color="primary"
              />
            ) : (
              ""
            )}
          </div>
        </Toolbar>
        <Table className="table">
          <Thead>
            <Tr key={"trini"}>
              {columns.map((cols, i) => {
                return <Th key={i + "_header_th_" + cols}>{cols}</Th>;
              })}
                {actions}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, key) => (
              <Tr key={key + "tr"}>
                {this.props.view
                  ? this.props.view.map((view, i) => {
                      if (
                        this.props.select !== undefined &&
                        this.props.select[view]
                      ) {
                        // eslint-disable-next-line
                        return Object.entries(this.props.select[view]).map(
                          (data, s) => {
                            data = Object.entries(data[1]);
                            if (row[view] === data[0][1]) {
                              return (
                                <Td align="center" key={i + "_tbody_td"}>
                                  {data[1][1]}
                                </Td>
                              );
                            }
                          }
                        );
                      } else {
                        return (
                          <Td align="center" key={i + "_tbody_td"}>
                            {row[view]}
                          </Td>
                        );
                      }
                    })
                  : Object.entries(row).map((col, i) => {
                      if (
                        this.props.select !== undefined &&
                        this.props.select[col[0]] !== undefined
                      ) {
                        // eslint-disable-next-line
                        return Object.entries(this.props.select[col[0]]).map(
                          (data, s) => {
                            data = Object.entries(data[1]);
                            if (
                              row[col[0]] === data[0][1] &&
                              data[1][1] !== undefined
                            ) {
                              return (
                                <Td align="center" key={i + "_tbody_td"}>
                                  {data[1][1]}
                                </Td>
                              );
                            }
                          }
                        );
                      } else {
                        return (
                          <Td align="center" key={i + "_tbody_td"}>
                            {row[col[0]]}
                          </Td>
                        );
                      }
                    })}
                <Td>
                  {options.handleDetail !== undefined ? (
                    <TooltipIcon
                      title={
                        options.detailtitle !== undefined
                          ? options.detailtitle
                          : "Detalle"
                      }
                      action={() =>
                        options.handleDetail(Object.entries(row)[0][1])
                      }
                      icon={List}
                      size="small"
                      color="secondary"
                    />
                  ) : (
                    ""
                  )}
                  {options.handleDetail2 !== undefined ? (
                    <TooltipIcon
                      title="Documentos"
                      action={() =>
                        options.handleDetail2(Object.entries(row)[0][1])
                      }
                      icon={Gavel}
                      size="small"
                      color="primary"
                    />
                  ) : (
                    ""
                  )}
                  {options.handleEdit !== undefined ? (
                    <TooltipIcon
                      title="Editar"
                      action={() =>
                        options.handleEdit(Object.entries(row)[0][1])
                      }
                      icon={EditIcon}
                      size="small"
                      color="primary"
                    />
                  ) : (
                    ""
                  )}
                  {options.handleDelete !== undefined ? (
                    <TooltipIcon
                      title="Borrar"
                      action={() =>
                        this.handleOpenDelete(Object.entries(row)[0][1])
                      }
                      icon={DeleteIcon}
                      size="small"
                      color="secondary"
                    />
                  ) : (
                    ""
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage=""
                colSpan={4}
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={Paginate}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <ConfirmationDialogRaw
          open={this.state.openDelete}
          onClose={this.handleCloseDelete}
          value={this.state.primaryValue}
          title="Borrar"
          subtitle="¿ Está seguro de borrar el registro ?"
        />
      </div>
    );
  }
}
export default withStyles(styles)(Tableini);
