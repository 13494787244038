import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import styles from '../../assets/css/Table'


const Transition = React.forwardRef((props, ref) => <Slide direction="up" {...props} ref={ref} />);

class ModalAction extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    const { classes, modalNuevo, handleModal, handleSubmit, title, form } = this.props;
    return (
      <Dialog
        fullScreen
        open={modalNuevo}
        onClose={() => handleModal(false)}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar} style={{ backgroundColor: "black" }}>
          <Toolbar>
            <IconButton color="inherit" onClick={() => handleModal(false)} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {title}
            </Typography>
            <IconButton color="inherit" onClick={() => handleSubmit()} aria-label="Save">
              <SaveIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div>
          {form}
        </div>
      </Dialog>
    );
  }
}
export default withStyles(styles)(ModalAction);
