import React,{Component} from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';


class TooltipIcon extends Component {

  constructor(props) {
      super(props)
      this.state = {
      }
  }

  render() {
    const {title,action,icon,size,color} = this.props;
    return (
      <Tooltip title={title}>
          <IconButton color={color} aria-label={title} onClick={() => action()} fontSize={size}>
              {React.createElement(icon)}
          </IconButton>
      </Tooltip>
    );
  }
}

export default TooltipIcon;
