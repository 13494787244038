import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, TextField, Grid, Typography, Button } from "@material-ui/core";
import styles from "../../assets/Table";
import axios from "axios";
import { openSnackbar } from "../../components/msg/msg";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nit: "",
      razsoc: "",
      telefono: "",
      celular: "",
      email: "",
      sigla: "",
      direccion: "",
    };
    this.consulta();
  }

  setField(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  consulta() {
    axios.get("mia08/All/").then(async (result) => {
      if (result.status === 200) {
        const { flag, msg, data } = result.data;
        if (flag === false) {
          openSnackbar({ message: msg, type: "error" });
        } else {
          data.data.items.map((data) => {
            this.setState(data);
            return "";
          });
        }
      }
    });
  }

  guardar() {
    axios.post("mia08/save/", this.state).then(async (result) => {
      if (result.status === 200) {
        const { flag, msg } = result.data;
        if (flag === false) {
          openSnackbar({ message: msg, type: "error" });
        } else {
          openSnackbar({ message: msg, type: "success" });
        }
      } else {
        openSnackbar({ message: result.statusText, type: "error" });
      }
    });
  }

  render() {
    const { classes } = this.props;
    /* const { modalNuevo, isLoading } = this.state;
    if (isLoading === true) {
      return (<CircularProgress disableShrink />);
    }*/
    return (
      <div className={classes.container}>
        <Paper className={classes.root}>
          <Typography component="h1" variant="h4" align="center">
            Datos de la entidad
          </Typography>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  // type="number"
                  name="nit"
                  label="Nit de la empresa"
                  fullWidth
                  onChange={(e) => this.setField(e)}
                  value={this.state.nit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  name="razsoc"
                  label="Razón social"
                  fullWidth
                  onChange={(e) => this.setField(e)}
                  value={this.state.razsoc}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  name="sigla"
                  label="Siglas"
                  fullWidth
                  onChange={(e) => this.setField(e)}
                  value={this.state.sigla}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="direccion"
                  label="Direccion"
                  fullWidth
                  onChange={(e) => this.setField(e)}
                  value={this.state.direccion}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  name="telefono"
                  label="Telefono"
                  type="number"
                  fullWidth
                  onChange={(e) => this.setField(e)}
                  value={this.state.telefono}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  name="celular"
                  label="Celular"
                  type="number"
                  fullWidth
                  onChange={(e) => this.setField(e)}
                  value={this.state.celular}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  name="email"
                  label="Correo Electronico"
                  type="email"
                  fullWidth
                  onChange={(e) => this.setField(e)}
                  value={this.state.email}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={(e) => this.guardar()}
                >
                  Actualizar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}
export default withStyles(styles)(Index);
