import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, CircularProgress, Typography } from '@material-ui/core';
//components
import styles from '../../assets/Table';
import { openSnackbar } from '../../components/msg/msg';
import axios from 'axios';
import { TransferList } from './lista';
import Select from 'react-select';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      nopermite: {},
      permite: {},
      usuario: '',
      usuarioarray: {},
    };
    this.title = 'Permisos por usuario';
  }
  componentDidMount() {
    this.consulta();
  }

  consulta() {
    this.setState({ isLoading: true });
    axios
      .get('gener02/All/')
      .then((result) => {
        if (result.status === 200) {
          const { data, flag, msg } = result.data;
          if (flag === false) {
            openSnackbar({ message: msg, type: 'error' });
          } else {
            this.setState({
              usuarioarray: data.data.items,
              isLoading: false,
            });
          }
        } else {
          openSnackbar({ message: result.statusText, type: 'error' });
        }
      })
      .catch((error) => {
        openSnackbar({ message: 'Error al consumir xx', type: 'error' });
      });
  }

  change(event) {
    this.setState({
      usuario: event,
      isLoading: true,
    });
    if (event.value !== '') {
      axios
        .get('gener42/permisos/' + event.value)
        .then((result) => {
          if (result.status === 200) {
            const { data, flag, msg } = result.data;
            if (flag === false) {
              openSnackbar({ message: msg, type: 'error' });
            } else {
              this.setState({
                nopermite: data.nopermite,
                permite: data.permite,
                isLoading: false,
              });
            }
          } else {
            openSnackbar({ message: result.statusText, type: 'error' });
          }
        })
        .catch((error) => {
          openSnackbar({ message: 'Error al consumir xx', type: 'error' });
        });
    }
  }

  select() {
    let options = [{ value: '@', label: 'Seleccione....' }];
    if (this.state.usuarioarray[0] !== undefined) {
      this.state.usuarioarray.map((data) => {
        options.push({ value: data.usuario, label: data.nombre });
        return '';
      });
    }
    return (
      <Select
        value={this.state.usuario}
        onChange={(e) => {
          this.change(e);
        }}
        options={options}
        style={{ width: '100%' }}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const { isLoading } = this.state;
    console.log(this.state.usuario.value !== '@');
    if (isLoading === true) {
      return <CircularProgress disableShrink />;
    }
    return (
      <Paper className={classes.root} style={{ height: '200%' }}>
        <Typography variant="h6">{this.title}</Typography>
        {this.select()}
        {(this.state.nopermite[0] !== undefined ||
          this.state.permite[0] !== undefined) &&
        this.state.usuario.value !== '@' ? (
          <TransferList props={this.state} />
        ) : (
          ''
        )}
      </Paper>
    );
  }
}
export default withStyles(styles)(Index);
