import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import axios from "axios";
import { openSnackbar } from "../../components/msg/msg";
import { Grid } from "@material-ui/core";

const styles = {
  root: {
    width: "40%",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },

  container: {
    maxHeight: 300,
    float: "left",
  },
};
class SimpleTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conductor: [],
      cliente: [],
    };
  }

  componentDidMount() {
    this.consulta();
  }

  consulta() {
    axios
      .get("mia30/califica/")
      .then((result) => {
        if (result.status === 200) {
          const { dataco, datacl, flag, msg } = result.data;
          if (flag === false) {
            openSnackbar({ message: msg, type: "error" });
          } else {
            dataco.map((info, index) =>
              this.setState({
                conductor: [
                  ...this.state.conductor,
                  {
                    name: info.prinom + " " + info.priape + " " + info.segape,
                    calificacion: info.calificacion,
                  },
                ],
              })
            );
            datacl.map((info, index) =>
              this.setState({
                cliente: [
                  ...this.state.cliente,
                  {
                    name: info.nombre,
                    calificacion: info.calificacion,
                  },
                ],
              })
            );
          }
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consumir xx", type: "error" });
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={5} style={{ marginRight: "12%" }}>
          <Typography
            variant="h6"
            style={{ textAlign: "center" }}
            gutterBottom
            component="h6"
          >
            Conductores
          </Typography>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell align="right">Calificación</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.conductor.map((n, index) => (
                  <TableRow key={index + "_lista_conductor"}>
                    <TableCell component="th" scope="row">
                      {n.name}
                    </TableCell>
                    <TableCell align="right">{n.calificacion}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={5}>
          <Typography
            variant="h6"
            style={{ textAlign: "center" }}
            gutterBottom
            component="h6"
          >
            Clientes
          </Typography>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell align="right">Calificación</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.cliente.map((n, index) => (
                  <TableRow key={index + "_lista_clientes"}>
                    <TableCell component="th" scope="row">
                      {n.name}
                    </TableCell>
                    <TableCell align="right">{n.calificacion}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(SimpleTable);
