import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  CircularProgress,
  Typography,
  TextField,
  MenuItem,
} from '@material-ui/core';
//components
import styles from '../../assets/Table';
import { openSnackbar } from '../../components/msg/msg';
import axios from 'axios';
import { TransferList } from './lista';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      nopermite: {},
      permite: {},
      tipfun: '',
      tipfunarray: {},
    };
    this.title = 'Permisos por funcionario';
  }
  componentDidMount() {
    this.consulta();
  }

  consulta() {
    this.setState({ isLoading: true });
    axios
      .get('gener21/All/')
      .then((result) => {
        if (result.status === 200) {
          const { data, flag, msg } = result.data;
          if (flag === false) {
            openSnackbar({ message: msg, type: 'error' });
          } else {
            this.setState({
              tipfunarray: data.data.items,
              isLoading: false,
            });
          }
        } else {
          openSnackbar({ message: result.statusText, type: 'error' });
        }
      })
      .catch((error) => {
        openSnackbar({ message: 'Error al consumir xx', type: 'error' });
      });
  }

  change(event) {
    this.setState({
      [event.target.name]: event.target.value,
      isLoading: true,
    });
    if (event.target.name === 'tipfun' && event.target.value !== '') {
      axios
        .get('gener41/permisos/' + event.target.value)
        .then((result) => {
          if (result.status === 200) {
            const { data, flag, msg } = result.data;
            if (flag === false) {
              openSnackbar({ message: msg, type: 'error' });
            } else {
              this.setState({
                nopermite: data.nopermite,
                permite: data.permite,
                isLoading: false,
              });
            }
          } else {
            openSnackbar({ message: result.statusText, type: 'error' });
          }
        })
        .catch((error) => {
          openSnackbar({ message: 'Error al consumir xx', type: 'error' });
        });
    } else {
      openSnackbar({
        message: 'No selecciono ningun funcionario',
        type: 'error',
      });
    }
  }
  Selecttipfun = () => {
    return (
      <TextField
        id={'tipfun'}
        name={'tipfun'}
        value={this.state.tipfun}
        select
        label={'Tipo de funcionario'}
        onChange={(e) => {
          this.change(e);
        }}
        style={{ width: '100%' }}
      >
        <MenuItem key={'selection'} value={'@'}>
          {'Tipo de funcionario *'}
        </MenuItem>

        {this.state.tipfunarray[0] !== undefined
          ? this.state.tipfunarray.map((data, i) => {
              return (
                <MenuItem key={'selection' + i} value={data.tipfun}>
                  {data.detalle}
                </MenuItem>
              );
            })
          : ''}
      </TextField>
    );
  };

  render() {
    const { classes } = this.props;
    const { isLoading } = this.state;
    if (isLoading === true) {
      return <CircularProgress disableShrink />;
    }
    return (
      <Paper className={classes.root}>
        <Typography variant="h6">{this.title}</Typography>
        {this.Selecttipfun()}
        {(this.state.nopermite[0] !== undefined ||
          this.state.permite[0] !== undefined) &&
        this.state.tipfun !== '@' ? (
          <TransferList props={this.state} />
        ) : (
          ''
        )}
      </Paper>
    );
  }
}
export default withStyles(styles)(Index);
