import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
//components
import Tableini from "../../components/table/table";
import ModalAction from "../../components/modal/modal-basic";
import styles from "../../assets/Table";
import { Form } from "../../components/form/basic";
import { Formik } from "formik";
import * as Yup from "yup";
import { openSnackbar } from "../../components/msg/msg";
import axios from "axios";
import Detail from "./detail";

var fileDownload = require("js-file-download");

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "M", //Master = M, Detail = D
      isLoading: false,
      modalNuevo: false,
      isEdit: false,
      values: {
        tipveh: "",
        detalle: "",
        estado: "",
      },
      select: {
        estado: [
          { codigo: "A", detalle: "ACTIVO" },
          { codigo: "I", detalle: "INACTIVO" },
        ],
      },
      view: ["tipveh", "detalle", "estado"],
    };
    this.title = "Tipos de Vehículos";
    this.url = "mia09";
    this.nuevo = this.nuevo.bind(this);
    this.reporte = this.reporte.bind(this);
    this.detail = this.detail.bind(this);
    this.openModalNuevo = this.openModalNuevo.bind(this);
    this.save = this.save.bind(this);
    this.editar = this.editar.bind(this);
    this.borrar = this.borrar.bind(this);
    this.isUnique = this.isUnique.bind(this);
    this.options = {
      edit: true,
      handleEdit: this.editar,
      delete: true,
      handleDelete: this.borrar,
      filter: true,
      report: true,
      handleReport: this.reporte,
      nuevo: true,
      handleNuevo: this.nuevo,
      detail: true,
      detailtitle: "conceptos de cobro",
      handleDetail: this.detail,
    };
    this.validate = {};
    Object.entries(this.state.values).map((field) => {
      this.validate[field[0]] = Yup.string("").required(
        field[0] + " is required"
      );
      return "";
    });
    this.validationSchema = Yup.object(this.validate);
  }

  handleDateChange = (field, value, props) => {
    // eslint-disable-next-line
    this.state.values = props;
    this.setState({
      values: {
        ...this.state.values,
        [field]: value,
      },
    });
  };

  openModalNuevo(value) {
    this.setState({ modalNuevo: value });
  }

  nuevo() {
    axios.get("mia09/maxitem/").then((result) => {
      if (result.status === 200) {
        const { data, flag, msg } = result.data;
        if (flag === false) {
          openSnackbar({ message: msg, type: "error" });
        } else {
          this.setState({
            type: "M",
            modalNuevo: true,
            isEdit: false,
            values: {
              tipveh: data.tipveh,
              detalle: "",
              estado: "A",
            },
          });
        }
      } else {
        openSnackbar({ message: result.statusText, type: "error" });
      }
    });
  }

  detail(data) {
    this.setState({
      type: "D",
      modalNuevo: true,
      values: {
        tipveh: data,
        detalle: "",
        estado: "",
      },
    });
  }

  save(values) {
    axios
      .post(this.url + "/save", values)
      .then((result) => {
        if (result.status === 200) {
          const { flag, msg } = result.data;
          if (flag === false) {
            openSnackbar({ message: msg, type: "error" });
          } else {
            openSnackbar({ message: msg, type: "success" });
            this.openModalNuevo(false);
            this.tableini.search();
          }
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consultar", type: "error" });
      });
  }

  editar(tipveh) {
    axios
      .get(this.url + "/tipveh/" + tipveh)
      .then((result) => {
        if (result.status === 200) {
          const { data, flag, msg } = result.data;
          if (flag === false) {
            openSnackbar({ message: msg, type: "error" });
          } else {
            this.setState({
              values: data,
              isEdit: true,
              type: "M",
              modalNuevo: true,
            });
          }
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consumir xx", type: "error" });
      });
  }

  borrar(tipveh) {
    axios
      .delete(this.url + "/tipveh/" + tipveh)
      .then((result) => {
        if (result.status === 200) {
          const { flag, msg } = result.data;
          if (flag === false) {
            openSnackbar({ message: msg, type: "error" });
          } else {
            openSnackbar({ message: msg, type: "success" });
            this.tableini.search();
          }
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consumir", type: "error" });
      });
  }

  reporte(tipo) {
    axios
      .get(this.url + "/reporte/" + tipo, {
        responseType: "blob",
        timeout: 30000,
      })
      .then((result) => {
        if (result.status === 200) {
          let file = "reporte.";
          if (tipo === "pdf") file += "pdf";
          else if (tipo === "excel") file += "xls";
          fileDownload(result.data, file);
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consultar", type: "error" });
      });
  }

  submitMyForm = null;
  handleSubmit = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };
  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  isUnique = async (value) => {
    let res = await axios.get(this.url + "/isUnique/" + value);
    return await res.data;
  };

  render() {
    console.log(this.state.values);
    const { classes } = this.props;
    const { modalNuevo, isLoading, type } = this.state;
    if (isLoading === true) {
      return <CircularProgress disableShrink />;
    }
    return (
      <div className={classes.container}>
        <Paper className={classes.root}>
          <Tableini
            options={this.options}
            url={this.url}
            title={this.title}
            view={this.state.view}
            select={this.state.select}
            onRef={(ref) => (this.tableini = ref)}
          />
        </Paper>
        <ModalAction
          title={type === "M" ? this.title : "Tarifas por Tipo de Vehículo"}
          modalNuevo={modalNuevo}
          handleModal={this.openModalNuevo}
          handleSubmit={this.handleSubmit}
          form={
            <Formik
              enableReinitialize={true}
              render={(props) =>
                type === "M" ? (
                  <Form
                    isEdit={this.state.isEdit}
                    select={this.state.select}
                    bindSubmitForm={this.bindSubmitForm}
                    handleDateChange={this.handleDateChange}
                    {...props}
                  />
                ) : (
                  <Detail {...props} />
                )
              }
              initialValues={this.state.values}
              validationSchema={this.validationSchema}
              validate={(values) => {
                let field = Object.entries(values)[0][0];
                if (this.state.isEdit === false && field !== "") {
                  let errors = {};
                  return new Promise((resolve, reject) => {
                    this.isUnique(values[field]).then((response) => {
                      if (response.flag === false) {
                        errors[field] = response.msg;
                        reject(errors);
                      }
                      resolve("done");
                    });
                  });
                }
              }}
              onSubmit={(values, { setSubmitting }) => {
                this.save(values);
                setSubmitting(false);
              }}
            />
          }
        />
      </div>
    );
  }
}
export default withStyles(styles)(Index);
