import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import styles from '../../assets/css/Menu'
import routes from '../../routes';
class MenuMia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      permisos: {},
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (e) => {
    this.setState({ [e]: !this.state[e] });
  };

  componentDidMount() {
  }

  render() {
    const { classes } = this.props;
    const mapeo = routes(this.props);
    return (
      <div key={"principal"}>
        <List className={classes.root} key={"list"} >
          {mapeo.map((menu, id) => {
            if (menu.dropdown !== undefined) {
              return (
                <div key={id + "div"}>
                  <ListItem button key={id + "menu"} onClick={() => this.handleClick(menu.dropdown)} >
                    {React.createElement(menu.icon)}
                    <ListItemText primary={menu.title} />
                    {this.state[menu.dropdown] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse key={menu.dropdown} component="li" in={this.state[menu.dropdown]} timeout="auto" unmountOnExit>
                    <List disablePadding key={id + "sublist"}>
                      {menu.nodes.map((sitem, sid) => {
                        return (
                          <ListItem key={sid + "submenu"} button={true} component={Link} to={sitem.url} className={classes.nested}>
                            <ListItemText primary={sitem.title} />
                          </ListItem>
                        )
                      })}
                    </List>
                  </Collapse>
                </div>
              )
            } else {
              return (
                <ListItem button={true} component={Link} to={menu.url} key={id + "list"}>
                  {React.createElement(menu.icon)}
                  <ListItemText primary={menu.title} />
                </ListItem>
              )
            }
          })}
        </List>
      </div>
    )
  }
}

MenuMia.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MenuMia);
