import * as React from "react";
import {
  SortingState,
  EditingState,
  PagingState,
  SummaryState,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedSummary,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  DragDropProvider,
  TableColumnReordering,
  TableFixedColumns,
} from "@devexpress/dx-react-grid-material-ui";
import {
  Paper,
  Button,
  IconButton,
  Input,
  Select,
  MenuItem,
  TableCell,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/core/styles";
import { CurrencyTypeProvider } from "../../components/currency-type-provider";
import { openSnackbar } from "../../components/msg/msg";
import axios from "axios";

const styles = (theme) => ({
  lookupEditCell: {
    paddingTop: theme.spacing(0.875),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  dialog: {
    width: "calc(100% - 16px)",
  },
  inputRoot: {
    width: "100%",
  },
});

const AddButton = ({ onExecute }) => (
  <div style={{ textAlign: "center" }}>
    <Button color="primary" onClick={onExecute} title="Create new row">
      Nuevo
    </Button>
  </div>
);

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Editar">
    <EditIcon />
  </IconButton>
);

const DeleteButton = ({ onExecute }) => (
  <IconButton
    onClick={() => {
      if (window.confirm("¿está seguro de que desea eliminar este registro?")) {
        onExecute();
      }
    }}
    title="Eliminar"
  >
    <DeleteIcon />
  </IconButton>
);

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Guardar">
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancelar">
    <CancelIcon />
  </IconButton>
);

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton,
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return <CommandButton onExecute={onExecute} />;
};

var availableValues = {
  codcon: [],
};

const LookupEditCellBase = ({
  availableColumnValues,
  value,
  onValueChange,
  classes,
}) => (
  <TableCell className={classes.lookupEditCell}>
    <Select
      value={value}
      onChange={(event) => onValueChange(event.target.value)}
      input={<Input classes={{ root: classes.inputRoot }} />}
    >
      {availableColumnValues.map((item, index) => {
        item = Object.entries(availableColumnValues[index]);
        return (
          <MenuItem key={index + "item"} value={item[0][1]}>
            {item[1][1]}
          </MenuItem>
        );
      })}
    </Select>
  </TableCell>
);
export const LookupEditCell = withStyles(styles, {
  name: "ControlledModeDemo",
})(LookupEditCellBase);

const Cell = (props) => {
  /* Object.entries(props.row).map(data => {
    if (availableValues[data[0]] !== undefined) {
      availableValues[data[0]].map(subdata => {
        if (subdata[data[0]] === data[1]) {
          props.row[data[0]] = subdata.detalle;
        }
      })
    }
  })*/
  return <Table.Cell {...props} />;
};

const EditCell = (props) => {
  const { column } = props;
  const availableColumnValues = availableValues[column.name];
  if (availableColumnValues) {
    return (
      <LookupEditCell
        {...props}
        availableColumnValues={availableColumnValues}
      />
    );
  }
  return <TableEditRow.Cell {...props} />;
};

const getRowId = (row) => row.id;
class DemoBase extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: "codcon", title: "Concepto" },
        { name: "valor", title: "Valor" },
      ],
      tableColumnExtensions: [
        { columnName: "codcon", width: 180 },
        { columnName: "valor", width: 180, align: "right" },
      ],
      rows: [],
      sorting: [],
      editingRowIds: [],
      addedRows: [],
      rowChanges: {},
      currentPage: 0,
      pageSize: 0,
      columnOrder: ["codcon", "valor"],
      currencyColumns: ["valor"],
      leftFixedColumns: [TableEditColumn.COLUMN_TYPE],
      totalSummaryItems: [{ columnName: "valor", type: "sum" }],
    };
    const getStateRows = () => {
      const { rows } = this.state;
      return rows;
    };
    this.changeSorting = (sorting) => this.setState({ sorting });
    this.changeEditingRowIds = (editingRowIds) =>
      this.setState({ editingRowIds });
    this.changeAddedRows = (addedRows) =>
      this.setState({
        addedRows: addedRows.map((row) =>
          Object.keys(row).length
            ? row
            : {
                valor: 0,
                codcon: availableValues.codcon[0],
              }
        ),
      });
    this.changeRowChanges = (rowChanges) => this.setState({ rowChanges });
    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });
    this.commitChanges = async ({ added, changed, deleted }) => {
      let { rows } = this.state;
      if (added) {
        added[0]["tipveh"] = this.props.values.tipveh;
        await this.request({
          url: "mia15",
          opt: "post",
          action: "/save",
          option: "/",
          info: added[0],
        });
        const startingAddedId =
          rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        rows = [
          ...rows,
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...row,
          })),
        ];
      }
      if (changed) {
        rows = await rows.map((item) => {
          if (changed[item.id]) {
            this.request({
              url: "mia15",
              opt: "post",
              action: "/save",
              option: "/",
              info: { ...item, ...changed[item.id] },
            });
            return { ...item, ...changed[item.id] };
          } else {
            return item;
          }
        });
      }
      if (deleted) {
        rows = await this.deleteRows(deleted);
      }
      await this.request({
        url: "mia15",
        opt: "get",
        action: "/All",
        option: "/0/30/" + this.props.values.tipveh,
        info: [],
      });
      this.setState({ rows });
    };
    this.deleteRows = async (deletedIds) => {
      const rows = getStateRows().slice();
      await deletedIds.forEach((rowId) => {
        const index = rows.findIndex((row) => row.id === rowId);
        if (index > -1) {
          this.request({
            url: "mia15",
            opt: "delete",
            action: "/tipveh",
            option: "/" + rows[index]["tipveh"] + "/" + rows[index]["codcon"],
            info: rows[index],
          });
          rows.splice(index, 1);
        }
      });
      return rows;
    };
    this.changeColumnOrder = (order) => {
      this.setState({ columnOrder: order });
    };
    this.request({
      url: "mia14",
      opt: "get",
      action: "/All",
      option: "/",
      info: [],
    });
    this.request({
      url: "mia15",
      opt: "get",
      action: "/All",
      option: "/0/30/" + this.props.values.tipveh,
      info: [],
    });
  }

  request = async (data) => {
    let { url, action, option, opt, info } = data;
    await axios[opt](url + action + option, info).then((result) => {
      if (result.status === 200) {
        const { data, flag, msg } = result.data;
        if (flag === false) {
          openSnackbar({ message: msg, type: "error" });
        } else {
          if (action === "/All" && url === "mia14") {
            availableValues["codcon"] = data.data.items;
          } else if (action === "/All" && url === "mia15") {
            data.data.items.map((item, index) => {
              item["id"] = index;
              item["valor"] = Number(item.valor);
              return "";
            });
            this.setState({ rows: data.data.items });
          }
        }
      } else {
        openSnackbar({ message: result.statusText, type: "error" });
      }
    });
  };

  render() {
    const {
      rows,
      columns,
      tableColumnExtensions,
      sorting,
      editingRowIds,
      addedRows,
      rowChanges,
      currentPage,
      pageSize,
      columnOrder,
      currencyColumns,
      leftFixedColumns,
      totalSummaryItems,
    } = this.state;
    return (
      <Paper>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={this.changePageSize}
          />
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={this.changeEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={this.changeRowChanges}
            addedRows={addedRows}
            onAddedRowsChange={this.changeAddedRows}
            onCommitChanges={this.commitChanges}
          />
          <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSorting />
          <IntegratedPaging />
          <IntegratedSummary />
          <CurrencyTypeProvider for={currencyColumns} />
          <DragDropProvider />
          <Table
            columnExtensions={tableColumnExtensions}
            cellComponent={Cell}
          />
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={this.changeColumnOrder}
          />
          <TableHeaderRow showSortingControls />
          <TableEditRow cellComponent={EditCell} />
          <TableEditColumn
            width={170}
            showAddCommand={!addedRows.length}
            showEditCommand
            showDeleteCommand
            commandComponent={Command}
          />
          <TableFixedColumns leftColumns={leftFixedColumns} />
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles, { name: "ControlledModeDemo" })(DemoBase);
