import React from "react";
import { TextField, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';

class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.autocomplete = null;
        this.autocompletes = [];
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    }

    handlePlaceChanged() {
        const place = this.autocomplete.getPlace();
        if (place !== undefined && place.geometry !== undefined) {
            this.props.Funcion(place, this.props.id);
        }
    }

    complete(elem) {
        var { lat, lng } = this.props;
        lat = parseFloat(lat.toPrecision(6));
        lng = parseFloat(lng.toPrecision(6))
        if (lat === 0 && lng === 0) {
            lat = 4.29887;
            lng = -74.8175;
        }
        var bangaloreBounds = new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng(lat, lng),
            new window.google.maps.LatLng(lat, lng),
        );
        this.autocomplete = new window.google.maps.places.Autocomplete(
            (
                elem
            ), {
            componentRestrictions: { 'country': 'co' },
            bounds: bangaloreBounds,
        });
        this.autocomplete.addListener('place_changed', this.handlePlaceChanged)
    }

    render() {
        return (
            <div className="custom-wrapper">
                <TextField
                    inputRef={(elem) => { if (elem !== null) this.complete(elem); }}
                    placeholder={this.props.placeholder || 'Dirección'}
                    label={this.props.placeholder || 'Dirección'}
                    fullWidth={true}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                />
            </div>
        );
    }
}

export default SearchBar;