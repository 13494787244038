import * as React from "react";
import {
  SortingState,
  EditingState,
  PagingState,
  SummaryState,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedSummary,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  DragDropProvider,
  TableColumnReordering,
  TableFixedColumns,
} from "@devexpress/dx-react-grid-material-ui";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TableCell from "@material-ui/core/TableCell";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/core/styles";
import { CurrencyTypeProvider } from "../../components/currency-type-provider";
import { openSnackbar } from "../../components/msg/msg";
import axios from "axios";

const styles = (theme) => ({
  lookupEditCell: {
    paddingTop: theme.spacing(0.875),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  dialog: {
    width: "calc(100% - 16px)",
  },
  inputRoot: {
    width: "100%",
  },
});

const AddButton = ({ onExecute }) => (
  <div style={{ textAlign: "center" }}>
    <Button color="primary" onClick={onExecute} title="Agregar nuevo vehículo">
      New
    </Button>
  </div>
);

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Editar vehículo">
    <EditIcon />
  </IconButton>
);

const DeleteButton = ({ onExecute }) => (
  <IconButton
    onClick={() => {
      // eslint-disable-next-line
      if (window.confirm("Está seguro que quiere eliminar el registro ?")) {
        onExecute();
      }
    }}
    title="Eliminar registro"
  >
    <DeleteIcon />
  </IconButton>
);

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes">
    <SaveIcon />
  </IconButton>
);

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
    <CancelIcon />
  </IconButton>
);

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton,
};

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return <CommandButton onExecute={onExecute} />;
};

var availableValues = {
  numveh: [],
};

const LookupEditCellBase = ({
  availableColumnValues,
  value,
  onValueChange,
  classes,
}) => (
  <TableCell className={classes.lookupEditCell}>
    <Select
      value={value}
      onChange={(event) => onValueChange(event.target.value)}
      input={<Input classes={{ root: classes.inputRoot }} />}
    >
      {availableColumnValues.map((item, index) => {
        item = Object.entries(availableColumnValues[index]);
        return (
          <MenuItem key={index + "item"} value={item[0][1]}>
            {item[3][1]}
          </MenuItem>
        );
      })}
    </Select>
  </TableCell>
);
export const LookupEditCell = withStyles(styles, {
  name: "ControlledModeDemo",
})(LookupEditCellBase);

const Cell = (props) => {
  return <Table.Cell {...props} />;
};

const EditCell = (props) => {
  const { column } = props;
  const availableColumnValues = availableValues[column.name];
  if (availableColumnValues) {
    return (
      <LookupEditCell
        {...props}
        availableColumnValues={availableColumnValues}
      />
    );
  }
  return <TableEditRow.Cell {...props} />;
};

const getRowId = (row) => row.id;
class DemoBase extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: [{ name: "numveh", title: "Vehiculo" }],
      tableColumnExtensions: [
        { columnName: "numveh", width: 120, align: "right" },
      ],
      rows: [],
      sorting: [],
      editingRowIds: [],
      addedRows: [],
      rowChanges: {},
      currentPage: 0,
      pageSize: 0,
      columnOrder: ["numdoc", "numveh"],
      currencyColumns: [],
      leftFixedColumns: [TableEditColumn.COLUMN_TYPE],
      totalSummaryItems: [],
    };
    const getStateRows = () => {
      const { rows } = this.state;
      return rows;
    };
    this.changeSorting = (sorting) => this.setState({ sorting });
    this.changeEditingRowIds = (editingRowIds) =>
      this.setState({ editingRowIds });
    this.changeAddedRows = (addedRows) =>
      this.setState({
        addedRows: addedRows.map((row) => (Object.keys(row).length ? row : {})),
      });
    this.changeRowChanges = (rowChanges) => this.setState({ rowChanges });
    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });
    this.commitChanges = ({ added, changed, deleted }) => {
      let { rows } = this.state;
      if (added) {
        added[0]["numdoc"] = this.props.values.numdoc;
        this.request({
          url: "mia28",
          opt: "post",
          action: "/save",
          option: "/",
          info: added[0],
        });
        const startingAddedId =
          rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        rows = [
          ...rows,
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...row,
          })),
        ];
      }
      if (changed) {
        rows = rows.map((item) => {
          if (changed[item.id]) {
            this.request({
              url: "mia28",
              opt: "post",
              action: "/save",
              option: "/",
              info: { ...item, ...changed[item.id] },
            });
            return { ...item, ...changed[item.id] };
          } else {
            return item;
          }
        });
      }
      if (deleted) {
        rows = this.deleteRows(deleted);
      }
      this.request({
        url: "mia28",
        opt: "get",
        action: "/All",
        option: "/0/30/" + this.props.values.numdoc,
        info: [],
      });
      this.setState({ rows });
    };
    this.deleteRows = (deletedIds) => {
      const rows = getStateRows().slice();
      deletedIds.forEach((rowId) => {
        const index = rows.findIndex((row) => row.id === rowId);
        if (index > -1) {
          console.log(rows[index]);
          this.request({
            url: "mia28",
            opt: "delete",
            action: "/numdoc",
            option: "/" + rows[index]["numdoc"] + "/" + rows[index]["numveh"],
            info: rows[index],
          });
          rows.splice(index, 1);
        }
      });
      return rows;
    };
    this.changeColumnOrder = (order) => {
      this.setState({ columnOrder: order });
    };
    this.request({
      url: "mia17",
      opt: "get",
      action: "/All",
      option: "/",
      info: [],
    });
    this.request({
      url: "mia28",
      opt: "get",
      action: "/All",
      option: "/0/30/" + this.props.values.numdoc,
      info: [],
    });
  }
  request = async (data) => {
    let { url, action, option, opt, info } = data;
    await axios[opt](url + action + option, info)
      .then((result) => {
        if (result.status === 200) {
          const { data, flag, msg } = result.data;
          if (flag === false) {
            openSnackbar({ message: msg, type: "error" });
          } else {
            if (action === "/All" && url === "mia28") {
              data.data.items.map(
                (item, index) => (data.data.items[index]["id"] = index)
              );
              this.setState({ rows: data.data.items });
            } else if (url === "mia17") {
              availableValues["numveh"] = data.data.items;
            }
          }
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consumir xx", type: "error" });
      });
  };
  render() {
    const {
      rows,
      columns,
      tableColumnExtensions,
      sorting,
      editingRowIds,
      addedRows,
      rowChanges,
      currentPage,
      pageSize,
      columnOrder,
      currencyColumns,
      leftFixedColumns,
      totalSummaryItems,
    } = this.state;
    return (
      <Paper>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={this.changePageSize}
          />
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={this.changeEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={this.changeRowChanges}
            addedRows={addedRows}
            onAddedRowsChange={this.changeAddedRows}
            onCommitChanges={this.commitChanges}
          />
          <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSorting />
          <IntegratedPaging />
          <IntegratedSummary />
          <CurrencyTypeProvider for={currencyColumns} />
          <DragDropProvider />
          <Table
            columnExtensions={tableColumnExtensions}
            cellComponent={Cell}
          />
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={this.changeColumnOrder}
          />
          <TableHeaderRow showSortingControls />
          <TableEditRow cellComponent={EditCell} />
          <TableEditColumn
            width={170}
            showAddCommand={!addedRows.length}
            showEditCommand
            showDeleteCommand
            commandComponent={Command}
          />
          <TableFixedColumns leftColumns={leftFixedColumns} />
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles, { name: "ControlledModeDemo" })(DemoBase);
