import React from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export const LoginForm = (props) => {
  const {
    values: { email, password },
    errors,
    touched,
    handleChange,
    setFieldTouched,
    handleSubmit,
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <FormControl margin="normal" required fullWidth>
        <TextField
          id="email"
          name="email"
          helperText={touched.email ? errors.email : ""}
          error={touched.email && Boolean(errors.email)}
          label="Email/Usuario"
          value={email}
          onChange={change.bind(null, "email")}
        />
      </FormControl>
      <FormControl margin="normal" required fullWidth>
        <TextField
          id="password"
          name="password"
          type="password"
          helperText={touched.password ? errors.password : ""}
          error={touched.password && Boolean(errors.password)}
          label="Password"
          value={password}
          onChange={change.bind(null, "password")}
        />
      </FormControl>
      <FormControl margin="normal" required fullWidth>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          style={{
            backgroundColor: "black",
            color: "white",
          }}
        >
          Ingresar
        </Button>
      </FormControl>
    </form>
  );
};
