import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Logo from "../../assets/image/logo1.png";

const styles = {
  root: {
    maxWidth: 345,
    marginLeft: "32%",
    marginTop: "10%",
  },
  media: {
    height: 140,
  },
};

class nomatch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <CardActionArea>
          <img
            src={Logo}
            className="App-logo"
            alt="logo"
            style={{
              width: "50%",
              height: "70%",
              marginTop: "10%",
              marginLeft: "20%",
            }}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              {" "}
              No existe{" "}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {" "}
              La ruta que ingresa es incorrecta, por favor verifique la ruta{" "}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}
export default withStyles(styles)(nomatch);
