import "date-fns";
import * as React from "react";
import {
  SortingState,
  EditingState,
  PagingState,
  SummaryState,
  IntegratedPaging,
  IntegratedSorting,
  IntegratedSummary,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
  DragDropProvider,
  TableColumnReordering,
  TableFixedColumns,
} from "@devexpress/dx-react-grid-material-ui";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import { CurrencyTypeProvider } from "../../components/currency-type-provider";
import { openSnackbar } from "../../components/msg/msg";
import axios from "axios";
import { Command } from "../../components/buttons/crud";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const styles = (theme) => ({
  lookupEditCell: {
    paddingTop: theme.spacing(0.875),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  dialog: {
    width: "calc(100% - 16px)",
  },
  inputRoot: {
    width: "100%",
  },
});

var availableValues = {
  tipdoc: "",
};
var availableColumnValuesfecha = {
  fecha: [],
};

var availableColumnValuesfile = {
  archivo: [],
};

const LookupEditCellBase = ({
  availableColumnValues,
  value,
  onValueChange,
  classes,
}) => (
  <TableCell className={classes.lookupEditCell}>
    <Select
      value={value === undefined ? "@" : value}
      onChange={(event) => onValueChange(event.target.value)}
      input={<Input classes={{ root: classes.inputRoot }} />}
    >
      {availableColumnValues.map((data, index) => {
        data = Object.entries(data);
        return (
          <MenuItem key={index} value={data[0][1]}>
            {data[1][1]}
          </MenuItem>
        );
      })}
    </Select>
  </TableCell>
);
export const LookupEditCell = withStyles(styles, {
  name: "ControlledModeDemo",
})(LookupEditCellBase);

const Cell = (props) => {
  return <Table.Cell {...props} />;
};

const EditCell = (props) => {
  const { column, onValueChange, value } = props;
  const availableColumnValues = availableValues[column.name];
  if (availableColumnValues) {
    return (
      <LookupEditCell
        {...props}
        availableColumnValues={availableColumnValues}
      />
    );
  }
  const fechas = availableColumnValuesfecha[column.name];
  if (fechas) {
    return (
      <TableCell>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            invalidLabel={"seleccione"}
            name={column.name}
            openTo="day"
            format="dd/MM/yyyy"
            value={value === undefined ? "x" : value}
            onChange={(event) => onValueChange(event)}
          />
        </MuiPickersUtilsProvider>
      </TableCell>
    );
  }
  const file = availableColumnValuesfile[column.name];
  if (file) {
    return (
      <TableCell>
        <input type="file" id={"archivo"} />
      </TableCell>
    );
  }
  return <TableEditRow.Cell {...props} />;
};

const getRowId = (row) => row.id;
class DemoBase extends React.PureComponent {
  //inicio de clase
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { name: "tipdoc", title: "Tipo documento" },
        { name: "fecha", title: "Fecha" },
        { name: "archivo", title: "Archivo" },
      ],
      tableColumnExtensions: [],
      rows: [],
      sorting: [],
      editingRowIds: [],
      addedRows: [],
      rowChanges: {},
      currentPage: 0,
      pageSize: 0,
      currencyColumns: [],
      leftFixedColumns: [TableEditColumn.COLUMN_TYPE],
      totalSummaryItems: [],
    };
    const getStateRows = () => {
      const { rows } = this.state;
      return rows;
    };
    this.changeSorting = (sorting) => this.setState({ sorting });
    this.changeEditingRowIds = (editingRowIds) =>
      this.setState({ editingRowIds });
    this.changeAddedRows = (addedRows) =>
      this.setState({
        addedRows: addedRows.map((row) => (Object.keys(row).length ? row : {})),
      });
    this.changeRowChanges = (rowChanges) => this.setState({ rowChanges });
    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });
    this.commitChanges = async ({ added, changed, deleted }) => {
      let { rows } = this.state;
      if (added) {
        added[0]["numveh"] = this.props.values.numveh;
        await this.save({
          url: "mia18",
          opt: "post",
          action: "/save",
          option: "/",
          info: added[0],
        });
        const startingAddedId =
          rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
        rows = [
          ...rows,
          ...added.map((row, index) => ({
            id: startingAddedId + index,
            ...row,
          })),
        ];
      }
      if (changed) {
        rows = rows.map(async (item) => {
          if (changed[item.id]) {
            await this.save({
              url: "mia18",
              opt: "post",
              action: "/save",
              option: "/",
              info: { ...item, ...changed[item.id] },
            });
            return { ...item, ...changed[item.id] };
          } else {
            return item;
          }
        });
      }
      if (deleted) {
        rows = await this.deleteRows(deleted);
        await this.sleep(2000);
      }
      this.request({
        url: "mia18",
        opt: "get",
        action: "/All",
        option: "/0/30/" + this.props.values.numveh,
        info: [],
      });
    };
    this.deleteRows = async (deletedIds) => {
      const rows = getStateRows().slice();
      await deletedIds.forEach(async (rowId) => {
        const index = rows.findIndex((row) => row.id === rowId);
        if (index > -1) {
          await this.request({
            url: "mia18",
            opt: "delete",
            action: "/id",
            option: "/" + rows[index]["id"],
            info: rows[index],
          });
          rows.splice(index, 1);
        }
      });
      console.log(0);
      return rows;
    };
    this.changeColumnOrder = (order) => {
      this.setState({ columnOrder: order });
    };
    this.request({
      url: "mia18",
      opt: "get",
      action: "/All",
      option: "/0/30/" + this.props.values.numveh,
      info: [],
    });
    this.request({
      url: "mia16",
      opt: "get",
      action: "/All",
      option: "",
      info: [],
    });
  }

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  request = async (data) => {
    let { url, action, option, opt, info } = data;
    await axios[opt](url + action + option, info).then((result) => {
      if (result.status === 200) {
        const { data, flag, msg } = result.data;
        if (flag === false) {
          openSnackbar({ message: msg, type: "error" });
        } else {
          if (url === "mia18" && action === "/All") {
            this.setState({ rows: data.data.items });
          }
          if (url === "mia16") {
            availableValues["tipdoc"] = data.data.items;
          }
        }
      } else {
        openSnackbar({ message: result.statusText, type: "error" });
      }
    });
  };

  save = async (data) => {
    let { url, action, option, opt, info } = data;
    var formData = new FormData();
    var imagefile = document.querySelector("#archivo");
    formData.append("file", imagefile.files[0]);
    Object.entries(info).map((data) => {
      if (data[0] === "fecha") {
        var date = new Date(data[1]),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        formData.append(data[0], [date.getFullYear(), mnth, day].join("-"));
      } else {
        formData.append(data[0], data[1]);
      }
      return "";
    });
    await axios[opt](url + action + option, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((result) => {
      if (result.status === 200) {
        const { data, flag, msg } = result.data;
        if (flag === false) {
          openSnackbar({ message: msg, type: "error" });
        } else {
          if (url === "mia18" && action === "/All") {
            this.setState({ rows: data.data.items });
          }
          if (url === "mia16") {
            availableValues["tipdoc"] = data.data.items;
          }
        }
      } else {
        openSnackbar({ message: result.statusText, type: "error" });
      }
    });
  };

  render() {
    const {
      rows,
      columns,
      tableColumnExtensions,
      sorting,
      editingRowIds,
      addedRows,
      rowChanges,
      currentPage,
      pageSize,
      columnOrder,
      currencyColumns,
      leftFixedColumns,
      totalSummaryItems,
    } = this.state;
    return (
      <Paper>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState
            sorting={sorting}
            onSortingChange={this.changeSorting}
          />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={this.changeCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={this.changePageSize}
          />
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={this.changeEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={this.changeRowChanges}
            addedRows={addedRows}
            onAddedRowsChange={this.changeAddedRows}
            onCommitChanges={this.commitChanges}
          />
          <SummaryState totalItems={totalSummaryItems} />
          <IntegratedSorting />
          <IntegratedPaging />
          <IntegratedSummary />
          <CurrencyTypeProvider for={currencyColumns} />
          <DragDropProvider />
          <Table
            columnExtensions={tableColumnExtensions}
            cellComponent={Cell}
          />
          <TableColumnReordering
            order={columnOrder}
            onOrderChange={this.changeColumnOrder}
          />
          <TableHeaderRow showSortingControls />
          <TableEditRow cellComponent={EditCell} />
          <TableEditColumn
            width={170}
            showAddCommand={!addedRows.length}
            showEditCommand
            showDeleteCommand
            commandComponent={Command}
          />
          <TableFixedColumns leftColumns={leftFixedColumns} />
        </Grid>
      </Paper>
    );
  }
} //fin clase

export default withStyles(styles, { name: "ControlledModeDemo" })(DemoBase);
