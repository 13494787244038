import Axios from "axios";
import { openSnackbar } from "../../components/msg/msg";

export const Autos = (elem, autos) => {
  Axios.get("mia42/Conductores")
    .then((result) => {
      if (result.status === 200) {
        const { data, flag, msg } = result.data;
        if (flag === false) {
          openSnackbar({ message: msg, type: "error" });
        } else {
          const before_autos = elem.state.autos.length;
          const actual_autos = data.length;
          if (before_autos !== actual_autos) {
            elem.setState({
              autos: data,
            });
          }
          data.map((info) => {
            if (elem.tipveh_list[info.tipveh] === undefined) {
              elem.tipveh_list = {
                ...elem.tipveh_list,
                [info.tipveh]: {
                  tipveh: info.tipveh,
                  numdoc: info.numdoc,
                  detalle: info.detalle,
                },
              };
            }
            return "";
          });
        }
      } else {
        openSnackbar({ message: result.statusText, type: "error" });
      }
    })
    .catch((error) => {
      openSnackbar({ message: "Error al consumir xx" + error, type: "error" });
    });
};

export const EnviarSolicitud = async (elem) => {
  let token_persona = await elem.props.cookies.get("token_auth");
  Axios.post("mia30/getCarsActive", {
    lat: await elem.state.formu_cliente.data_map_origen.geometry.location.lat(),
    log: await elem.state.formu_cliente.data_map_origen.geometry.location.lng(),
  }).then((result) => {
    if (result.status === 200) {
      const { flag, msg } = result.data;
      if (flag === false) {
        openSnackbar({ message: msg, type: "error" });
      } else {
        var otros = {
          tipveh: elem.state.formu_cliente.vehiculo,
          valcal: 1,
          distance: 1,
          forpag: 1,
        };
        var datos_enviar = {
          otros,
          origen: {
            latitude: elem.state.formu_cliente.data_map_origen.geometry.location.lat(),
            longitude: elem.state.formu_cliente.data_map_origen.geometry.location.lng(),
            address: elem.state.formu_cliente.direccion_origen,
          },
          destination: {
            latitude: elem.state.formu_cliente.data_map_destino.geometry.location.lat(),
            longitude: elem.state.formu_cliente.data_map_destino.geometry.location.lng(),
            address: elem.state.formu_cliente.direccion_destino,
          },
          persona: {
            nombre:
              elem.state.formu_cliente.nombre +
              " " +
              elem.state.formu_cliente.apellido,
            celular: elem.state.formu_cliente.celular,
            calificacion: 0,
            usucli: 1,
            token: token_persona,
          },
          driver: result.data.data,
          intentos: 0,
        };
        if (datos_enviar.driver[datos_enviar.intentos] !== undefined) {
          elem.socket.emit("findDriver", datos_enviar);
          elem.setState({
            estado_solicitud: "P",
          });
          setTimeout(() => {
            elem.socket.emit("cancelDriver", datos_enviar);
          }, 10000);
        } else {
          openSnackbar({
            message:
              "No encontramos vehículos cerca de tu zona, intenta más tarde.",
            type: "error",
          });
        }
      }
    } else {
      openSnackbar({ message: result.statusText, type: "error" });
    }
  });
};

export const cancelRequest = (elem) => {
  elem.setState({
    estado_solicitud: "",
  });
  elem.socket.emit("cancelClient", false);
};

export const ConsultaToken = (elem, data) => {
  Axios.get("consultas/consultatoken/" + btoa(data.token) + "/" + data.tipo)
    .then((result) => {
      const tipo = data.tipo;
      if (result.status === 200) {
        const { data, flag, msg } = result.data;
        if (flag === false) {
          openSnackbar({ message: msg, type: "error" });
        } else {
          if (tipo === "DR") {
            elem.setState({
              panic: data,
              info: {},
              activeMarker: elem.marcas_mapa[data.numdoc],
              showingInfoWindow: true,
              drawer: false,
            });
          } else {
            elem.setState({
              panic: data,
              info: {},
              activeMarker: elem.marcas_mapa[data.conductor.numdoc],
              showingInfoWindow: true,
              drawer: false,
            });
          }
        }
      } else {
        openSnackbar({ message: result.statusText, type: "error" });
      }
    })
    .catch((error) => {
      console.log(error);
      openSnackbar({ message: "Error en Consulta Token", type: "error" });
    });
};
