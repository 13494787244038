import React, { Fragment } from "react";
import {
  Grid,
  Typography,
  Fab,
  TextField,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  List,
  ListItem,
  Divider,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { Send, DirectionsCar, Cancel, Warning } from "@material-ui/icons";
import { Marker, InfoWindow } from "google-maps-react";
const Taxi = require("../../assets/image/TAXI.png");

export const FormuCliente = (elem) => {
  return (
    <Grid container spacing={2} style={{ margin: "2%" }}>
      <Grid item xs={6}>
        <Typography variant="h5" style={{ textAlign: "center" }}>
          {"Datos del cliente"}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5" style={{ textAlign: "center" }}>
          {"Vehículos disponibles"}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid item xs>
          <TextField
            label="Nombre"
            id="nombre"
            fullWidth
            onChange={elem.ChangeData}
            value={elem.state.formu_cliente.nombre || ""}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs>
          <TextField
            label="Apellido"
            id="apellido"
            fullWidth
            onChange={elem.ChangeData}
            value={elem.state.formu_cliente.apellido || ""}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs>
          <TextField
            label="Celular"
            id="celular"
            fullWidth
            onChange={elem.ChangeData}
            value={elem.state.formu_cliente.celular || ""}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs>
          <TextField
            label="Email"
            id="email"
            fullWidth
            onChange={elem.ChangeData}
            value={elem.state.formu_cliente.email || ""}
            autoComplete="off"
          />
        </Grid>
        <Grid item xs>
          <TextField
            label="Vehículo"
            id="vehiculo"
            fullWidth
            value={elem.state.formu_cliente.vehiculo || ""}
            disabled={true}
            autoComplete="off"
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        {ListarAutos(elem)}
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        {FuncBtn(elem)}
      </Grid>
    </Grid>
  );
};

const FuncBtn = (elem) => {
  if (elem.state.estado_solicitud === "P") {
    return (
      <Fragment>
        <Grid item xs={12}>
          <CircularProgress size={48} />
        </Grid>
        <Grid item xs={12}>
          <Fab
            variant="extended"
            style={{ backgroundColor: "red", color: "white", margin: "auto" }}
            onClick={() => {
              elem.cancelRequest(elem);
            }}
          >
            <Cancel />
            {"Cancelar Solicitud"}
          </Fab>
        </Grid>
      </Fragment>
    );
  } else {
    return (
      <Fab
        variant="extended"
        style={{
          backgroundColor: elem.state.btn_solicitud.color,
          color: "white",
          margin: "auto",
        }}
        onClick={() => {
          elem.EnviarSolicitud(elem);
        }}
        disabled={elem.state.btn_solicitud.option}
      >
        {"Solicitar carrera"}
        <Send />
      </Fab>
    );
  }
};

const ListarAutos = (elem) => {
  return (
    <Grid container spacing={2}>
      {Object.entries(elem.tipveh_list).map((data) => {
        data = data[1];
        return (
          <Grid item xs key={"list_auto" + data.numdoc}>
            <Fab
              variant="extended"
              onClick={() => {
                llenarVehiculo(data, elem);
              }}
            >
              <DirectionsCar />
              {data.detalle}
            </Fab>
          </Grid>
        );
      })}
    </Grid>
  );
};

const llenarVehiculo = (data, elem) => {
  let formu_cliente = {
    ...elem.state.formu_cliente,
    vehiculo: data.tipveh + " - " + data.detalle,
  };
  elem.setState({ formu_cliente });
  elem.ValidateFields(formu_cliente);
};

export const ListarConductores = (elem) => {
  return (
    <List>
      <ListItem>
        <Typography variant="h5">{"Conductores Activos"}</Typography>
      </ListItem>
      <Divider />
      {elem.state.autos.map((data) => {
        return (
          <ListItem
            button
            key={data.numdoc}
            onClick={() => {
              InfoConductor(data, elem);
            }}
          >
            <ListItemIcon>
              <Avatar src={""}>{data.priape[0]}</Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                data.priape +
                " " +
                data.segape +
                " " +
                data.prinom +
                " " +
                data.segnom
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

const InfoConductor = (data, elem) => {
  console.log("InfoConductor L212");
  elem.setState({
    info: data,
    activeMarker: elem.marcas_mapa[data.numdoc],
    showingInfoWindow: true,
    Drawer: false,
    zoom: 18,
  });
};

export const ContentInfo = (elem) => {
  const { info, panic } = elem.state;
  console.log(panic);
  if (info.numdoc !== undefined && panic.numdoc === undefined) {
    return (
      <Card>
        <CardHeader
          avatar={<Avatar src={""}>{info.priape[0]}</Avatar>}
          title={
            info.priape +
            " " +
            info.segape +
            " " +
            info.prinom +
            " " +
            info.segnom
          }
          subheader={info.celular}
        />
        <CardContent>
          <Typography variant="caption" paragraph>
            Placa : {info.placa}
          </Typography>
          <Typography variant="caption" paragraph>
            Capacidad : {info.cantidad}
          </Typography>
          <Typography variant="caption" paragraph>
            Calificación : {info.calificacion}
          </Typography>
        </CardContent>
      </Card>
    );
  }
  if (panic.isDriver) {
    return (
      <Card>
        <CardHeader
          avatar={
            <Avatar src={""}>
              <Warning />
            </Avatar>
          }
          style={{ backgroundColor: "#f0a12a" }}
          title={
            panic.priape +
            " " +
            panic.segape +
            " " +
            panic.prinom +
            " " +
            panic.segnom
          }
          subheader={panic.celular}
        />
        <CardContent>
          <Typography variant="caption" paragraph>
            {" "}
            El conductor activó el botón de pánico,{" "}
          </Typography>
          <Typography variant="caption" paragraph>
            {" "}
            ponerse en contácto con el lo más pronto.{" "}
          </Typography>
        </CardContent>
      </Card>
    );
  } else {
    return (
      <Card>
        <CardHeader
          avatar={
            <Avatar src={""}>
              <Warning />
            </Avatar>
          }
          style={{ backgroundColor: "#f0a12a" }}
          title={panic.email}
          subheader={panic.celular}
        />
        <CardContent>
          <Typography variant="caption" paragraph>
            {" "}
            El cliente activó el botón de pánico,{" "}
          </Typography>
          <Typography variant="caption" paragraph>
            {" "}
            ponerse en contácto con el lo más pronto.{" "}
          </Typography>
        </CardContent>
      </Card>
    );
  }
};

export const PintarAutos = (elem) => {
  return elem.state.autos.map((data) => {
    return (
      <Marker
        key={"auto-" + data.numdoc}
        ref={elem.MarcaCargada}
        onClick={elem.onMarkerClick}
        info={data}
        position={{ lat: data.lat, lng: data.log }}
        icon={{
          url: Taxi,
          scaledSize: new window.google.maps.Size(30, 15),
        }}
      />
    );
  });
};

export const DataDriver = (elem) => {
  if (elem.state.estado_solicitud === "A") {
    return (
      <Dialog
        open={elem.state.DataDriver}
        onClose={() => {
          elem.setState({ DataDriver: false });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Servicio asignado"}</DialogTitle>
        {/* <DialogContent>
          <Typography variant="caption" paragraph>
            Nombre : {elem.state.datos_conductor.name}
          </Typography>
          <Typography variant="caption" paragraph>
            Placa : {elem.state.datos_conductor.carro.placa}
          </Typography>
        </DialogContent> */}
      </Dialog>
    );
  }
};
