const styles = theme => ({
    root: {
      width: '100%',
      overflowX: 'auto',
      marginTop: theme.spacing(3),
    },
    appBar: {
      position: 'relative',
    },
    flex: {
      flex: 1,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    dense: {
      marginTop: theme.spacing(19),
    },
    menu: {
      width: 200,
    },
    table: {
      minWidth: 700,
    },
    spacer: {
      flex: '1 1 50%',
    },
  });
  export default styles