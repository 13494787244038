import React, { Component } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { openSnackbar } from "../../components/msg/msg";

export default class Editable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
    };
  }

  render() {
    return (
      <MaterialTable
        title={""}
        columns={this.state.columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .get(
                "mia22/All/" +
                  query.page +
                  "/" +
                  query.pageSize +
                  "/" +
                  this.props.values.marca
              )
              .then((result) => {
                resolve({
                  data: result.data.data.data.items,
                  page: result.data.data.data.current - 1,
                  totalCount: parseInt(result.data.data.data.count),
                });
                let data_array = {};
                result.data.data.data.items.map(
                  (elem) =>
                    (data_array = {
                      ...data_array,
                      [elem.linea]: elem.detalle,
                    })
                );
                this.setState({
                  columns: [
                    { title: "Linea", field: "linea", lookup: data_array },
                  ],
                });
              });
          })
        }
        options={{
          search: false,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              axios.post("mia22/save/", newData).then((result) => {
                const { flag, msg } = result.data;
                if (flag === false) {
                  openSnackbar({ message: msg, type: "error" });
                } else {
                  openSnackbar({ message: msg, type: "success" });
                  resolve();
                }
              });
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              axios.post("mia22/save/", newData).then((result) => {
                const { flag, msg } = result.data;
                if (flag === false) {
                  openSnackbar({ message: msg, type: "error" });
                } else {
                  openSnackbar({ message: msg, type: "success" });
                  resolve();
                }
              });
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              axios.delete("mia22/linea/" + oldData.linea).then((result) => {
                const { flag, msg } = result.data;
                if (flag === false) {
                  openSnackbar({ message: msg, type: "error" });
                } else {
                  openSnackbar({ message: msg, type: "success" });
                  resolve();
                }
              });
            }),
        }}
      />
    );
  }
}
