import React, { Component } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { openSnackbar } from "../../components/msg/msg";

export default class Editable extends Component {
  constructor(props) {
    super(props);
    this.vehiculos = {};
    this.columns = [
      { title: "Vehículo", field: "numveh", lookup: this.vehiculos },
      { title: "Pripal", field: "pripal", lookup: { S: "SI", N: "NO" } },
      {
        title: "Estado",
        field: "estado",
        lookup: { A: "ACTIVO", I: "INACTIVO" },
      },
    ];
    this.state = {
      columns: this.columns,
    };
  }

  async componentDidMount() {
    this.vehiculos = await this.select("mia17/vehiculo");
    this.columns = [
      { title: "Vehículo", field: "numveh", lookup: this.vehiculos.data },
      { title: "Pripal", field: "pripal", lookup: { S: "SI", N: "NO" } },
      {
        title: "Estado",
        field: "estado",
        lookup: { A: "ACTIVO", I: "INACTIVO" },
      },
    ];
    this.setState({
      columns: this.columns,
    });
  }

  select = async (url) => {
    let res = await axios.get(url);
    let { data } = await res.data;
    return data;
  };

  maxitem = () => {
    axios.get("mia31/maxitem").then(async (result) => {
      this.setState({
        columns: [
          ...this.state.columns, //,
          //{ title: 'Linea', field: 'linea', initialEditValue: result.data.data },
        ],
      });
    });
  };

  render() {
    return (
      <MaterialTable
        title={""}
        columns={this.state.columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            axios
              .get(
                "mia31/All/" +
                  query.page +
                  "/" +
                  query.pageSize +
                  "/" +
                  this.props.values.numdoc
              )
              .then((result) => {
                //this.maxitem();
                resolve({
                  data: result.data.data.data.items,
                  page: result.data.data.data.current - 1,
                  totalCount: parseInt(result.data.data.data.count),
                });
              });
          })
        }
        options={{
          search: false,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              axios
                .post("mia31/save/", {
                  ...newData,
                  numdoc: this.props.values.numdoc,
                })
                .then((result) => {
                  const { flag, msg } = result.data;
                  if (flag === false) {
                    openSnackbar({ message: msg, type: "error" });
                  } else {
                    openSnackbar({ message: msg, type: "success" });
                    resolve();
                  }
                });
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              axios.post("mia31/save/", newData).then((result) => {
                const { flag, msg } = result.data;
                if (flag === false) {
                  openSnackbar({ message: msg, type: "error" });
                } else {
                  openSnackbar({ message: msg, type: "success" });
                  resolve();
                }
              });
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              axios
                .delete(
                  "mia31/numdoc/" +
                    this.props.values.numdoc +
                    "/" +
                    oldData.numveh
                )
                .then((result) => {
                  const { flag, msg } = result.data;
                  console.log(oldData);
                  if (flag === false) {
                    openSnackbar({ message: msg, type: "error" });
                  } else {
                    openSnackbar({ message: msg, type: "success" });
                    resolve();
                  }
                });
            }),
        }}
      />
    );
  }
}
