import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
//components
import Tableini from "../../components/table/table";
import styles from "../../assets/Table";
import { openSnackbar } from "../../components/msg/msg";
import axios from "axios";

var fileDownload = require("js-file-download");

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "M", //Master = M, Detail = D
      isLoading: false,
      modalNuevo: false,
      isEdit: false,
      values: {
        numdoc: "",
        coddoc: "",
        priape: "",
        segape: "",
        prinom: "",
        segnom: "",
        codsex: "",
        codciu: "",
        direccion: "",
        telefono: "",
        celular: "",
        email: "",
        codeps: "",
        tiplic: "",
        fecven: "",
        codgru: "",
        password: "",
      },
      select: {
        coddoc: {},
        codsex: {},
        codciu: {},
        codeps: {},
        tiplic: {},
        codgru: {},
        estado: [
          { codigo: "A", detalle: "ACTIVO" },
          { codigo: "I", detalle: "INACTIVO" },
        ],
      },
      fecha: {
        fecven: "",
      },
      view: ["numdoc", "prinom", "priape", "telefono","celular", "email", "fecven", "estado"],
    };
    this.title = "Consulta Conductor";
    this.url = "mia30";
    this.method = "All_feclic";
    this.reporte = this.reporte.bind(this);

    this.options = {
      action: false,
      filter: true,
      report: true,
      handleReport: this.reporte,
    };
  }

  reporte(tipo) {
    axios
      .get(this.url + "/reporte_feclic/" + tipo, {
        responseType: "blob",
        timeout: 30000,
      })
      .then((result) => {
        if (result.status === 200) {
          let file = "reporte.";
          if (tipo === "pdf") file += "pdf";
          else if (tipo === "excel") file += "xls";
          fileDownload(result.data, file);
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consultar", type: "error" });
      });
  }

  select = async (url, campo) => {
    let res = await axios.get(url);
    let { data } = await res.data;
    if (data !== undefined) {
      this.state.select[campo] = data.data.items;
    }
  };

  componentDidMount() {}
  render() {
    console.log(this.state.select);
    const { classes } = this.props;
    const { isLoading } = this.state;
    if (isLoading === true) {
      return <CircularProgress disableShrink />;
    }
    return (
      <div className={classes.container}>
        <Paper className={classes.root}>
          <Tableini
            options={this.options}
            url={this.url}
            title={this.title}
            onRef={(ref) => (this.tableini = ref)}
            view={this.state.view}
            select={this.state.select}
            method={this.method}
          />
        </Paper>
      </div>
    );
  }
}
export default withStyles(styles)(Index);
