import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
//components
import Tableini from "../../components/table/table";
import ModalAction from "../../components/modal/modal-basic";
import styles from "../../assets/Table";
import { Form } from "../../components/form/basic";
import { Formik } from "formik";
import * as Yup from "yup";
import { openSnackbar } from "../../components/msg/msg";
import axios from "axios";
import Detail from "./detail";

var fileDownload = require("js-file-download");

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "M", //Master = M, Detail = D
      isLoading: false,
      modalNuevo: false,
      isEdit: false,
      values: {
        numdoc: "",
        coddoc: "",
        priape: "",
        segape: "",
        prinom: "",
        segnom: "",
        codsex: "",
        codciu: "",
        direccion: "",
        telefono: "",
        celular: "",
        email: "",
        codeps: "",
        tiplic: "",
        fecven: "",
        codgru: "",
        password: "",
      },
      select: {
        coddoc: {},
        codsex: {},
        codciu: {},
        codeps: {},
        tiplic: {},
        codgru: {},
        estado: [
          { codigo: "A", detalle: "ACTIVO" },
          { codigo: "I", detalle: "INACTIVO" },
        ],
      },
      fecha: {
        fecven: "",
      },
      archivo: {
        foto : "",
      },
      view: ["numdoc", "prinom", "priape", "celular", "calificacion", "estado"],
    };
    this.title = "Conductores";
    this.url = "mia30";
    this.nuevo = this.nuevo.bind(this);
    this.reporte = this.reporte.bind(this);
    this.detail = this.detail.bind(this);
    this.openModalNuevo = this.openModalNuevo.bind(this);
    this.save = this.save.bind(this);
    this.editar = this.editar.bind(this);
    this.borrar = this.borrar.bind(this);
    this.isUnique = this.isUnique.bind(this);
    this.options = {
      edit: true,
      handleEdit: this.editar,
      delete: true,
      handleDelete: this.borrar,
      filter: true,
      report: true,
      handleReport: this.reporte,
      nuevo: true,
      handleNuevo: this.nuevo,
      detail: true,
      handleDetail: this.detail,
    };
    this.validate = {};
    this.novalida = ["segnom", "segape"];
    Object.entries(this.state.values).map((field) => {
      if (this.novalida.indexOf(field[0]) === -1)
        this.validate[field[0]] = Yup.string("").required(
          field[0] + " es requerido"
        );
      return "";
    });
    this.validationSchema = Yup.object(this.validate);
  }

  openModalNuevo(value) {
    this.setState({ modalNuevo: value });
  }

  nuevo() {
    this.setState({
      type: "M",
      modalNuevo: true,
      isEdit: false,
      values: {
        numdoc: "",
        coddoc: "",
        priape: "",
        segape: "",
        prinom: "",
        segnom: "",
        codsex: "",
        codciu: "",
        direccion: "",
        telefono: "",
        celular: "",
        email: "",
        codeps: "",
        tiplic: "",
        fecven: new Date(),
        codgru: "",
        password: "",
        foto : "",
      },
    });
  }

  detail(data) {
    this.setState({
      type: "D",
      modalNuevo: true,
      values: {
        numdoc: data,
        detalle: "",
      },
    });
  }

    save = async (values) => {
        var formData = new FormData();
        var imagefile = document.querySelector("#foto");
        formData.append("file", imagefile.files[0]);
        Object.entries(values).map((data) => {
            //Le damos el formato correcto a las fechas para que sean enviadas al backend
            if (data[0] === "fecven") {
                var date = new Date(data[1]),
                    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                    day = ("0" + date.getDate()).slice(-2);
                formData.append(data[0], [date.getFullYear(), mnth, day].join("-"));
            } else {
                formData.append(data[0], data[1]);
            }
            return "";
        });
        await axios
            .post(this.url + "/save/", formData,{
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            .then((result) => {
                if (result.status === 200) {
                    const { flag, msg } = result.data;
                    if (flag === false) {
                        openSnackbar({ message: msg, type: "error" });
                    } else {
                        openSnackbar({ message: msg, type: "success" });
                        this.openModalNuevo(false);
                        this.tableini.search();
                    }
                } else {
                    openSnackbar({ message: result.statusText, type: "error" });
                }
            })
            .catch((error) => {
                openSnackbar({ message: "Error al consultar", type: "error" });
            });
    }

  editar(numdoc) {
    axios
      .get(this.url + "/numdoc/" + numdoc)
      .then((result) => {
        if (result.status === 200) {
          const { data, flag, msg } = result.data;
          delete data.calificacion;
          if (flag === false) {
            openSnackbar({ message: msg, type: "error" });
          } else {
            this.setState({
              values: data,
              isEdit: true,
              type: "M",
              modalNuevo: true,
            });
          }
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consumir xx", type: "error" });
      });
  }

  borrar(numdoc) {
    axios
      .delete(this.url + "/numdoc/" + numdoc)
      .then((result) => {
        if (result.status === 200) {
          const { flag, msg } = result.data;
          if (flag === false) {
            openSnackbar({ message: msg, type: "error" });
          } else {
            openSnackbar({ message: msg, type: "success" });
            this.tableini.search();
          }
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consumir", type: "error" });
      });
  }

  reporte(tipo) {
    axios
      .get(this.url + "/reporte/" + tipo, {
        responseType: "blob",
        timeout: 30000,
      })
      .then((result) => {
        if (result.status === 200) {
          let file = "reporte.";
          if (tipo === "pdf") file += "pdf";
          else if (tipo === "excel") file += "xls";
          fileDownload(result.data, file);
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consultar", type: "error" });
      });
  }

  submitMyForm = null;
  handleSubmit = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };
  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  isUnique = async (value) => {
    let res = await axios.get(this.url + "/isUnique/" + value);
    return await res.data;
  };

  select = async (url, campo) => {
    let res = await axios.get(url);
    let { data } = await res.data;
    if (data !== undefined) {
      this.state.select[campo] = data.data.items;
    }
  };

  componentDidMount() {
    this.select("gener17/All", "codsex");
    this.select("gener18/All", "coddoc");
    this.select("gener08/All", "codciu");
    this.select("gener19/All", "codgru");
    this.select("mia19/select", "codeps");
    this.select("mia29/All", "tiplic");
  }

  handleDateChange = (field, value, props) => {
    // eslint-disable-next-line
    this.state.values = props;
    this.setState({
      values: {
        ...this.state.values,
        [field]: value,
      },
    });
  };

  render() {
    const { classes } = this.props;
    const { modalNuevo, isLoading, type } = this.state;
    if (isLoading === true) {
      return <CircularProgress disableShrink />;
    }
    return (
      <div className={classes.container}>
        <Paper className={classes.root}>
          <Tableini
            options={this.options}
            url={this.url}
            title={this.title}
            onRef={(ref) => (this.tableini = ref)}
            view={this.state.view}
            select={this.state.select}
          />
        </Paper>
        <ModalAction
          title={type === "M" ? this.title : this.title + " / Detalle"}
          modalNuevo={modalNuevo}
          handleModal={this.openModalNuevo}
          handleSubmit={this.handleSubmit}
          form={
            <Formik
              enableReinitialize={true}
              render={(props) => {
                if (type === "M") {
                  return (
                    <Form
                      isEdit={this.state.isEdit}
                      select={this.state.select}
                      fecha={this.state.fecha}
                      archivo={this.state.archivo}
                      bindSubmitForm={this.bindSubmitForm}
                      handleDateChange={this.handleDateChange}
                      {...props}
                    />
                  );
                } else if (type === "D") {
                  return <Detail {...props} />;
                }
              }}
              initialValues={this.state.values}
              validationSchema={this.validationSchema}
              validate={(values) => {
                let field = Object.entries(values)[0][0];
                if (this.state.isEdit === false && field === "") {
                  let errors = {};
                  return new Promise((resolve, reject) => {
                    this.isUnique(values[field]).then((response) => {
                      if (response.flag === false) {
                        errors[field] = response.msg;
                        reject(errors);
                      }
                      resolve("done");
                    });
                  });
                }
              }}
              onSubmit={(values, { setSubmitting }) => {
                this.save(values);
                setSubmitting(false);
              }}
            />
          }
        />
      </div>
    );
  }
}
export default withStyles(styles)(Index);
