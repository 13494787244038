import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper, CircularProgress } from "@material-ui/core";
//components
import Tableini from "../../components/table/table";
import ModalAction from "../../components/modal/modal-basic";
import styles from "../../assets/Table";
import { Form } from "../../components/form/basic";
import { Formik } from "formik";
import * as Yup from "yup";
import { openSnackbar } from "../../components/msg/msg";
import axios from "axios";
var fileDownload = require("js-file-download");

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      modalNuevo: false,
      isEdit: false,
      values: {
        forpag: "",
        detalle: "",
        estado: "",
      },
      select: {
        estado: [
          { codigo: "A", detalle: "ACTIVO" },
          { codigo: "I", detalle: "INACTIVO" },
        ],
      },
      view: ["forpag", "detalle", "estado"],
    };
    this.title = "Formas de pago";
    this.url = "mia06";
    this.nuevo = this.nuevo.bind(this);
    this.reporte = this.reporte.bind(this);
    this.openModalNuevo = this.openModalNuevo.bind(this);
    this.save = this.save.bind(this);
    this.editar = this.editar.bind(this);
    this.borrar = this.borrar.bind(this);
    this.isUnique = this.isUnique.bind(this);
    this.options = {
      edit: true,
      handleEdit: this.editar,
      delete: true,
      handleDelete: this.borrar,
      filter: true,
      report: false,
      handleReport: this.reporte,
      nuevo: true,
      handleNuevo: this.nuevo,
    };
    this.validate = {};
    Object.entries(this.state.values).map((field) => {
      this.validate[field[0]] = Yup.string("").required(
        field[0] + " is required"
      );
      return "";
    });
    this.validationSchema = Yup.object(this.validate);
  }

  handleDateChange = (field, value, props) => {
    // eslint-disable-next-line
    this.state.values = props;
    this.setState({
      values: {
        ...this.state.values,
        [field]: value,
      },
    });
  };

  openModalNuevo(value) {
    this.setState({ modalNuevo: value });
  }

  nuevo() {
    axios.get("mia06/maxitem").then((result) => {
      if (result.status === 200) {
        const { data, flag, msg } = result.data;
        if (flag === false) {
          openSnackbar({ message: msg, type: "error" });
        } else {
          this.setState({
            modalNuevo: true,
            isEdit: false,
            values: {
              forpag: data.forpag,
              detalle: "",
              estado: "A",
            },
          });
        }
      } else {
        openSnackbar({ message: result.statusText, type: "error" });
      }
    });
  }

  save(values) {
    axios
      .post(this.url + "/save", values)
      .then((result) => {
        if (result.status === 200) {
          const { flag, msg } = result.data;
          if (flag === false) {
            openSnackbar({ message: msg, type: "error" });
          } else {
            openSnackbar({ message: msg, type: "success" });
            this.openModalNuevo(false);
            this.tableini.search();
          }
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consultar", type: "error" });
      });
  }

  editar(forpag) {
    axios
      .get(this.url + "/forpag/" + forpag)
      .then((result) => {
        if (result.status === 200) {
          const { data, flag, msg } = result.data;
          if (flag === false) {
            openSnackbar({ message: msg, type: "error" });
          } else {
            this.setState({
              values: data,
              isEdit: true,
              modalNuevo: true,
            });
          }
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consumir xx", type: "error" });
      });
  }

  borrar(forpag) {
    axios
      .delete(this.url + "/forpag/" + forpag)
      .then((result) => {
        if (result.status === 200) {
          const { flag, msg } = result.data;
          if (flag === false) {
            openSnackbar({ message: msg, type: "error" });
          } else {
            openSnackbar({ message: msg, type: "success" });
            this.tableini.search();
          }
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consumir", type: "error" });
      });
  }

  reporte(tipo) {
    axios
      .get(this.url + "/reporte/" + tipo, {
        responseType: "blob",
        timeout: 30000,
      })
      .then((result) => {
        if (result.status === 200) {
          let file = "reporte.";
          if (tipo === "pdf") file += "pdf";
          else if (tipo === "excel") file += "xls";
          fileDownload(result.data, file);
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consultar", type: "error" });
      });
  }

  submitMyForm = null;
  handleSubmit = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };
  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  isUnique = async (value) => {
    let res = await axios.get(this.url + "/isUnique/" + value);
    return await res.data;
  };

  render() {
    const { classes } = this.props;
    const { modalNuevo, isLoading } = this.state;
    if (isLoading === true) {
      return <CircularProgress disableShrink />;
    }
    return (
      <div className={classes.container}>
        <Paper className={classes.root}>
          <Tableini
            options={this.options}
            url={this.url}
            title={this.title}
            view={this.state.view}
            select={this.state.select}
            columns={this.state.columns}
            onRef={(ref) => (this.tableini = ref)}
          />
        </Paper>
        <ModalAction
          title={this.title}
          modalNuevo={modalNuevo}
          handleModal={this.openModalNuevo}
          handleSubmit={this.handleSubmit}
          form={
            <Formik
              enableReinitialize={true}
              render={(props) => (
                <Form
                  isEdit={this.state.isEdit}
                  bindSubmitForm={this.bindSubmitForm}
                  colums={this.options.columns}
                  select={this.state.select}
                  handleDateChange={this.handleDateChange}
                  {...props}
                />
              )}
              initialValues={this.state.values}
              validationSchema={this.validationSchema}
              validate={(values) => {
                let field = Object.entries(values)[0][0];
                if (this.state.isEdit === false && field !== "") {
                  let errors = {};
                  return new Promise((resolve, reject) => {
                    this.isUnique(values[field]).then((response) => {
                      if (response.flag === false) {
                        errors[field] = response.msg;
                        reject(errors);
                      }
                      resolve("done");
                    });
                  });
                }
              }}
              onSubmit={(values, { setSubmitting }) => {
                this.save(values);
                setSubmitting(false);
              }}
            />
          }
        />
      </div>
    );
  }
}
export default withStyles(styles)(Index);
