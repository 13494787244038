import React, { Component } from "react";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import LineChart from "recharts/lib/chart/LineChart";
import Line from "recharts/lib/cartesian/Line";
import XAxis from "recharts/lib/cartesian/XAxis";
import YAxis from "recharts/lib/cartesian/YAxis";
import CartesianGrid from "recharts/lib/cartesian/CartesianGrid";
import Tooltip from "recharts/lib/component/Tooltip";
import Legend from "recharts/lib/component/Legend";
import axios from "axios";
import { openSnackbar } from "../../components/msg/msg";

class SimpleLineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datos: [],
    };
  }

  componentDidMount() {
    this.consulta();
  }
  consulta() {
    axios
      .get("mia05/careers/")
      .then((result) => {
        if (result.status === 200) {
          const { data, flag, msg } = result.data;
          if (flag === false) {
            openSnackbar({ message: msg, type: "error" });
          } else {
            let datos = [];
            for (let index = data.length; index >= 0; index--) {
              if (data[index] !== undefined) {
                datos.push({
                  name: data[index].fecini,
                  Carreras: parseInt(data[index].total),
                });
              }
            }
            this.setState({ datos });
          }
        } else {
          openSnackbar({ message: result.statusText, type: "error" });
        }
      })
      .catch((error) => {
        openSnackbar({ message: "Error al consumir xx", type: "error" });
      });
  }

  render() {
    return (
      // 99% per https://github.com/recharts/recharts/issues/172
      <ResponsiveContainer width="99%" height={320}>
        <LineChart data={this.state.datos}>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="Carreras" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
export default SimpleLineChart;
