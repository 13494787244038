import React, { Component } from 'react';
import { Paper, Grid, Typography, TextField, IconButton, TableHead, TableRow, TableContainer, Table, TableCell, TableBody, Button, SwipeableDrawer } from '@material-ui/core';
import { Search, Assignment } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { openSnackbar } from '../../components/msg/msg';
import styles from '../../assets/Table';
import Axios from 'axios';

class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numdoc: "",
            propietario: "",
            vehiculos: "",
            nombre: "",
            view: false,
            vehiculo: "",
            page: 0,
            rowsPerPage: 5,
        }
    }

    Consulta = () => {
        Axios.get("consultas/VehiculosPropietario/" + this.state.numdoc)
            .then(
                (result) => {
                    if (result.status === 200) {
                        const { data, flag, msg } = result.data;
                        if (flag === false) {
                            openSnackbar({ message: msg, type: 'error' });
                        } else {
                            if (data.propietario === false) {
                                openSnackbar({ message: "No tiene registros", type: 'error' });
                            } else {
                                this.setState({
                                    nombre: data.propietario.prinom + " " + data.propietario.segnom + " " + data.propietario.priape + " " + data.propietario.segape,
                                    propietario: data.propietario,
                                    vehiculos: data.vehiculos,
                                })
                            }
                        }
                    } else {
                        openSnackbar({ message: result.statusText, type: 'error' });
                    }
                }
            )
            .catch((error) => {
                openSnackbar({ message: "No se pudo ejecutar la solicitud", type: 'error' });
            });
    }

    TableContruct = (info) => {
        if (info.data[0] !== undefined && info.data[0] !== "") {
            return (
                <TableContainer className={this.props.classes.tablah}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {Object.entries(info.columns).map((data, index) => {
                                    return <TableCell key={index}> {data[1]} </TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {info.data.map((data) => {
                                if (data[0] === undefined && data.length === undefined) {
                                    return (
                                        <TableRow key={"Prow_1"}>
                                            {Object.entries(info.columns).map((subdata, subindex) => (
                                                <TableCell key={"Pcell_" + subindex}> {data[subdata[0]]} </TableCell>
                                            ))}
                                        </TableRow>
                                    )
                                } else if (data[0] !== undefined && data.length > 0) {
                                    return (
                                        data.map((subinfo, index) => (
                                            <TableRow key={"Srow_" + index}>
                                                {Object.entries(info.columns).map((subdata, subindex) => {
                                                    if (subdata[0] === "tipveh") {
                                                        return (
                                                            <TableCell key={"Scell_" + subindex}>
                                                                {subinfo.tipoVehiculo.detalle}
                                                            </TableCell>
                                                        )

                                                    }
                                                    if (subdata[0] === "opcion") {
                                                        return (
                                                            <TableCell key={"Scell_" + subindex}>
                                                                <Button onClick={() => { this.setState({ view: true, vehiculo: subinfo.numveh }) }}>
                                                                    <Assignment />
                                                                </Button>
                                                            </TableCell>
                                                        )
                                                    } else {
                                                        return <TableCell key={"Scell_" + subindex}> {subinfo[subdata[0]]} </TableCell>
                                                    }
                                                })}
                                            </TableRow>
                                        ))
                                    )
                                } else {
                                    return (
                                        <TableRow key={"Prow_1000000"}>
                                            <TableCell key={"Pcell_1000000"}> {"No hay registros para mostrar"} </TableCell>
                                        </TableRow>
                                    )
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
    }

    ModalInfo = () => {
        if (this.state.vehiculo) {
            let conductores = [];
            this.state.vehiculos.map(data => {
                if (data.numveh === this.state.vehiculo) {
                    data.conductores.map((conduct, X) => {
                        data.conductores[X].nombre = conduct.prinom + " " + conduct.segnom + " " + conduct.priape + " " + conduct.segape
                        return true
                    })
                    conductores = data.conductores;
                }
                return true;
            })
            return (
                <SwipeableDrawer
                    anchor="bottom"
                    open={this.state.view}
                    onClose={() => { this.setState({ view: false, vehiculo: "" }) }}
                    onOpen={() => { this.setState({ view: true, vehiculo: "" }) }}
                >
                    {this.TableContruct({
                        columns: {
                            numdoc: "Documento",
                            nombre: "Nombre",
                            email: "Email",
                            celular: "Celular",
                            calificacion: "Calificación",
                        },
                        data: [conductores]
                    })}
                </SwipeableDrawer>
            )
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <Paper className={classes.root} style={{ padding: "3%" }}>
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom component="h6">
                                {"Consultas por propietario"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField value={this.state.numdoc} onChange={(elem) => { this.setState({ numdoc: elem.target.value }) }} label="Número de documento" fullWidth />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton color="primary" aria-label="upload picture" component="span" onClick={this.Consulta}>
                                <Search />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "2%" }}>
                            <Typography variant="h6" gutterBottom component="h6">
                                {this.state.nombre}
                            </Typography>
                            {this.TableContruct({
                                columns: {
                                    celular: "Celular",
                                    email: "Email",
                                    direccion: "Direccion",
                                },
                                data: [this.state.propietario]
                            })}
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "2%" }}>
                            {this.TableContruct({
                                columns: {
                                    tipveh: "Tipo de vehículo",
                                    placa: "Placa",
                                    modelo: "Modelo",
                                    capacidad: "Capacidad",
                                    fecsoat: "Fecha del SOAT",
                                    fecimp: "Fecha del impuesto",
                                    opcion: "Opciones"
                                },
                                data: [this.state.vehiculos]
                            })}
                        </Grid>
                    </Grid>
                </Paper>
                {this.ModalInfo()}
            </div >
        );
    }
}

export default withStyles(styles)(Index);
